import { Amplify } from 'aws-amplify';
import Routes from './routes';
import { useCartStore, useGeneralStore, useKitchenStore } from './store';
import { useEffect, useRef } from 'react';
import { Auth } from '@aws-amplify/auth';
import { loginApi } from './services/AuthService';
import { ENV } from './env';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { shallow } from 'zustand/shallow';

Amplify.configure({
  region: 'us-east-1',
  userPoolId: ENV.AWS_USER_POOL,
  userPoolWebClientId: ENV.AWS_USER_POOL_CLIENT,
  endpoint: ENV.AUTH_URL,
  cookieStorage: {
    domain: ENV.DOMAIN,
  },
});

const client = new QueryClient();

function App() {
  const { setAuth } = useGeneralStore((state) => ({ setAuth: state.setAuth }), shallow);
  const { selectedKitchenData, setSelectedKitchen } = useKitchenStore(
    (state) => ({
      selectedKitchenData: state.selectedKitchenData!,
      setSelectedKitchen: state.setSelectedKitchen,
    }),
    shallow,
  );
  const { setDeliveryAddress } = useCartStore(
    (state) => ({
      setDeliveryAddress: state.setDeliveryAddress,
    }),
    shallow,
  );
  const selectedKitchenDataRef = useRef(selectedKitchenData);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        loginApi({
          country: selectedKitchenDataRef.current.country,
          kitchen: selectedKitchenDataRef.current.kitchen,
        })
          .then((data) => {
            setSelectedKitchen(data.kitchen);
            setDeliveryAddress(data.deliveryAddress);
            setAuth(true);
          })
          .catch(() => {
            setAuth(false);
          });
      })
      .catch(() => {
        setAuth(false);
      });
  }, [setAuth, setDeliveryAddress, setSelectedKitchen]);

  return (
    <QueryClientProvider client={client}>
      <Routes />
    </QueryClientProvider>
  );
}

export default App;
