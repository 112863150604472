import { Auth } from '@aws-amplify/auth';
import { Country, Kitchen } from '@calo/types';
import React, { useEffect, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { uniqBy } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { useGeneralStore, useKitchenStore } from '../../store';
import { Signin } from '../Signin';
import { assets } from '../../assets';
import { ENV } from '../../env';
import { AVAILABLE_KITCHENS_DATA } from '../../store/kitchenStore';
import { useServiceStatus } from '../../hooks';
import deliveryCar from '../../assets/icons/delivery-car.svg';
import logout from '../../assets/icons/logout.svg';
import arrowDown from '../../assets/icons/arrow-down.svg';
import arrowUp from '../../assets/icons/arrow-up.svg';
import helpWhiteLogo from '../../assets/icons/help-white.svg';

const SaudiStates = [
  { name: 'Saudi (Western Province)', kitchen: Kitchen.SA2, hack: 'WP' },
  { name: 'Saudi (Riyadh)', kitchen: Kitchen.SA1, hack: 'R' },
  { name: 'Saudi (Eastern Province)', kitchen: Kitchen.SA1, hack: 'EP' },
];

export const Header = () => {
  const handleModal = (modalId: string, checked = false) => {
    const myModal = document?.getElementById(modalId) as HTMLInputElement;
    if (myModal) myModal.checked = checked;
  };

  const navigate = useNavigate();
  const { serviceStatusData } = useServiceStatus();

  const { isAuth, setAuth } = useGeneralStore(
    (state) => ({ isAuth: state.isAuth, setAuth: state.setAuth }),
    shallow,
  );

  const { selectedKitchenData, setSelectedKitchen, hack, setHack } = useKitchenStore(
    (state) => ({
      selectedKitchenData: state.selectedKitchenData,
      setSelectedKitchen: state.setSelectedKitchen,
      hack: state.hack,
      setHack: state.setHack,
    }),
    shallow,
  );
  const countriesArray = useMemo(
    () => uniqBy(Object.values(AVAILABLE_KITCHENS_DATA), 'country'),
    [],
  );

  const [isSaudiClicked, setIsSaudiClicked] = useState(selectedKitchenData?.country === Country.SA);

  useEffect(() => {
    if (serviceStatusData?.country && selectedKitchenData === null) {
      const currentCountry = countriesArray.find(
        (country) => country.country === serviceStatusData.country,
      );
      if (!currentCountry || currentCountry.country === Country.SA) {
        setIsSaudiClicked(currentCountry?.country === Country.SA);
        handleModal('m2', true);
      } else {
        setSelectedKitchen(currentCountry.kitchen);
      }
    }
  }, [countriesArray, selectedKitchenData, serviceStatusData?.country, setSelectedKitchen]);

  return (
    <div className="fixed z-20 h-16 w-screen flex bg-primary p-4 md:px-24 lg:px-40 xl:px-56 2xl:px-72 flex-row items-center justify-between">
      <img
        onClick={() => navigate('/')}
        className="w-16 md:w-24 cursor-pointer"
        srcSet={[
          `${ENV.CDN_URL}/site/logo-white@1x.png`,
          `${ENV.CDN_URL}/site/logo-white@2x.png 2x`,
          `${ENV.CDN_URL}/site/logo-white@3x.png 3x`,
        ].join(', ')}
        alt="calo"
      />
      <div className="flex flex-row justify-center align-middle">
        {!isAuth ? (
          <>
            <label htmlFor="m2" className="cursor-pointer mr-4 m-auto">
              {selectedKitchenData ? (
                <img alt="flag" src={assets.flags[selectedKitchenData.country]} />
              ) : (
                'Select Country'
              )}
            </label>
            <span id="intercomMessenger" className={`flex-1 justify-end flex cursor-pointer mr-4`}>
              <img alt="help" src={helpWhiteLogo} />
            </span>
            {selectedKitchenData ? (
              <label htmlFor="m0" className="text-white cursor-pointer font-bold text-xl">
                Sign in
              </label>
            ) : null}
          </>
        ) : (
          <>
            <img
              onClick={() => navigate('/deliveries')}
              alt="car"
              className="cursor-pointer mr-2"
              src={deliveryCar}
            />
            <span id="intercomMessenger" className={`flex-1 justify-end flex cursor-pointer mr-2`}>
              <img alt="help" src={helpWhiteLogo} />
            </span>
            <label htmlFor="m1">
              <img alt="logout" className="cursor-pointer" src={logout} />
            </label>
          </>
        )}
      </div>

      {!isAuth ? (
        <>{selectedKitchenData ? <Signin modalId="m0" /> : null}</>
      ) : (
        <>
          <input type="checkbox" id="m1" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box text-center">
              <h3 className="font-bold text-3xl">Logout from account?</h3>
              <p className="py-3 text-lg">You can log back in anytime</p>
              <div className="modal-action pb-4">
                <label htmlFor="m1" className="w-full btn btn-outline">
                  Cancel
                </label>
              </div>
              <label
                className="w-full btn btn-outline btn-error"
                onClick={() => {
                  Auth.signOut();
                  setAuth(false);
                  const myModal = document?.getElementById('m1') as HTMLInputElement;
                  if (myModal) myModal.checked = false;
                }}>
                Logout
              </label>
            </div>
          </div>
        </>
      )}

      <input type="checkbox" id="m2" className="modal-toggle" />
      <label htmlFor="m2" className="modal cursor-pointer">
        <label
          htmlFor=""
          className="rounded-b-none modal-box max-h-full max-w-full w-full h-full md:rounded-b-2xl md:modal-box md:w-full md:h-fit">
          <h3 className="pb-2">Hello there</h3>
          <h3 className="font-bold text-xl">Where are you from?</h3>
          <div className="pt-4">
            {countriesArray.map(({ country, kitchen }) => (
              <div key={kitchen}>
                <label
                  onClick={() => {
                    if (country === Country.SA) {
                      return setIsSaudiClicked((oldValue) => !oldValue);
                    }
                    setHack(null);
                    setIsSaudiClicked(false);
                    setSelectedKitchen(kitchen);
                    handleModal('m2');
                  }}
                  className="label cursor-pointer border-b">
                  <span className="flex items-center gap-2">
                    <img alt="flag" src={assets.flags[country]} />
                    <label className="text-sm">{country}</label>
                  </span>
                  {country === Country.SA ? (
                    isSaudiClicked ? (
                      <img width={15} className="mr-1" src={arrowUp} />
                    ) : (
                      <img width={15} className="mr-1" src={arrowDown} />
                    )
                  ) : (
                    <input
                      readOnly
                      type="radio"
                      name="radio-10"
                      className="radio checked:bg-primary"
                      checked={selectedKitchenData?.country === country}
                    />
                  )}
                </label>
                {isSaudiClicked &&
                  country === Country.SA &&
                  SaudiStates.map((data) => (
                    <label
                      key={data.hack}
                      onClick={() => {
                        setSelectedKitchen(data.kitchen);
                        setHack(data.hack);
                        handleModal('m2');
                      }}
                      className="label cursor-pointer border-b ml-4">
                      <span className="flex items-center gap-2">
                        <label className="text-sm">{data.name}</label>
                      </span>
                      <input
                        readOnly
                        checked={hack === data.hack}
                        type="radio"
                        name="radio-10"
                        className="radio checked:bg-primary"
                      />
                    </label>
                  ))}
              </div>
            ))}
          </div>
        </label>
      </label>
    </div>
  );
};
