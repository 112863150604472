import { useEffect, useState } from 'react';

const getMediaData = () => {
  return {
    isMobile: window.innerWidth < 640,
    isTablet: window.innerWidth >= 640 && window.innerWidth < 1024,
    isDesktop: window.innerWidth >= 1024,
  };
};

export const useDocumentMedia = () => {
  const [mediaData, setMediaData] = useState({
    isMobile: false,
    isDesktop: false,
    isTablet: false,
  });

  const handleResize = () => setMediaData(getMediaData());

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return mediaData;
};
