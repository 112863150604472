import React from 'react';
import { Currency } from '@calo/types';

type OrderItemProps = {
  image?: string;
  name: string;
  description?: string;
  calories: string;
  price: number;
  currency: Currency;
  quantity: number;
  isLast?: boolean;
};

export const OrderItem = ({
  name,
  description,
  image,
  calories,
  price,
  currency,
  quantity,
  isLast,
}: OrderItemProps) => {
  return (
    <div>
      <div className="flex justify-between mb-4">
        <div className="flex gap-2 w-full ">
          <img height={100} width={100} className="rounded-md" src={image} alt="order" />
          <div className="flex flex-col w-full h-full gap-2 justify-between">
            <div>
              <p className="font-medium text-sm">{name}</p>
              <p className="text-grey-2 italic text-xs">{description}</p>
            </div>
            <div className="flex w-full justify-between items-end">
              <div>
                <p className="text-xs">{calories}</p>
                <p className="text-base font-bold">
                  {price} {currency}
                </p>
              </div>

              <label className="text-base font-bold">{quantity}</label>
            </div>
          </div>
        </div>
      </div>
      {!isLast && <div className="h-[1px] bg-grey-1 mb-4" />}
    </div>
  );
};
