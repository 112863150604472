import React, { useState } from 'react';
import {
  AddressTypeInput,
  Button,
  Card,
  Container,
  DeliveryLocation,
  LocationPicker,
  ScreenInfoHeader,
  TextInput,
} from '../components';
import { AddressType } from '@calo/types';
import { useCartStore, useKitchenStore } from '../store';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { withCart } from '../HOC';
import { Controller, useForm } from 'react-hook-form';
import { countryLatLng } from '../services/LocationService';

export const DeliveryAddress = withCart(() => {
  const { selectedKitchenData } = useKitchenStore(
    (state) => ({ selectedKitchenData: state.selectedKitchenData! }),
    shallow,
  );
  const { setDeliveryAddress, deliveryAddress } = useCartStore(
    (state) => ({
      setDeliveryAddress: state.setDeliveryAddress,
      deliveryAddress: state.deliveryAddress,
    }),
    shallow,
  );
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { isValid },
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      type: deliveryAddress?.type ?? AddressType.home,
      region: deliveryAddress?.region ?? '',
      building: deliveryAddress?.building ?? '',
      flat: deliveryAddress?.apartment ?? deliveryAddress?.office ?? '',
      district: deliveryAddress?.district ?? '',
      street: deliveryAddress?.street ?? '',
      notes: deliveryAddress?.notes ?? '',
    },
  });
  const onSubmit = (data: Record<string, any>) => {
    setDeliveryAddress({
      country: selectedKitchenData.country,
      lat: location.lat,
      lng: location.lng,
      ...(data.type === AddressType.home
        ? {
            type: data.type,
            apartment: data.flat,
            office: undefined,
          }
        : {
            type: data.type,
            apartment: undefined,
            office: data.flat,
          }),
      region: data.region,
      building: data.building,
      district: data.district,
      street: data.street,
      notes: data.notes,
    });
    navigate('/contact-details');
  };

  const [location, setLocation] = useState(
    deliveryAddress
      ? {
          lat: deliveryAddress.lat,
          lng: deliveryAddress.lng,
        }
      : countryLatLng[selectedKitchenData.country]!,
  );

  return (
    <Container>
      <ScreenInfoHeader title="Location Address" navToHome />
      <Card className="m-auto mb-3">
        <LocationPicker
          location={location}
          onChangeLocation={(newAddress) => {
            setLocation({ lat: newAddress.lat, lng: newAddress.lng });
            setValue('type', AddressType.home, { shouldValidate: true });
            setValue('region', newAddress.region ?? '', { shouldValidate: true });
            setValue('building', newAddress.building ?? '', { shouldValidate: true });
            setValue('flat', newAddress.apartment ?? '', { shouldValidate: true });
            setValue('district', newAddress.district ?? '', { shouldValidate: true });
          }}
        />
        <DeliveryLocation
          location={location}
          onClickEdit={() => {
            const myModal = document?.getElementById('location-picker-mobile') as HTMLInputElement;
            if (myModal) myModal.checked = true;
          }}
        />
      </Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="m-auto">
          <p className="text-base font-bold">Address Type</p>

          <Controller
            rules={{ required: true }}
            render={({ field }) => (
              <AddressTypeInput defaultAddress={field.value} onChange={field.onChange} />
            )}
            name="type"
            control={control}
          />

          <div className="mt-3 flex gap-2 justify-between">
            <Controller
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput title="Area" value={field.value} onChangeText={field.onChange} />
              )}
              name="region"
              control={control}
            />
          </div>
          <div className="mt-3 flex gap-2 justify-between">
            <Controller
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  containerClass="w-4/6"
                  title="Building"
                  value={field.value}
                  onChangeText={field.onChange}
                />
              )}
              name="building"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextInput
                  isOptional
                  containerClass="w-4/6"
                  title="Flat"
                  value={field.value}
                  onChangeText={field.onChange}
                />
              )}
              name="flat"
              control={control}
            />
          </div>
          <div className="mt-3 flex gap-2 justify-between">
            <Controller
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  containerClass="w-4/6"
                  title="Block"
                  value={field.value}
                  onChangeText={field.onChange}
                />
              )}
              name="district"
              control={control}
            />
            <Controller
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  containerClass="w-4/6"
                  title="Street"
                  value={field.value}
                  onChangeText={field.onChange}
                />
              )}
              name="street"
              control={control}
            />
          </div>
          <div className="mt-3 flex gap-2 justify-between">
            <Controller
              render={({ field }) => (
                <TextInput
                  isOptional
                  textInputClass="h-20"
                  title="Delivery Instructions"
                  multiLine
                  placeholder="Any specific delivery requests?"
                  value={field.value}
                  onChangeText={field.onChange}
                />
              )}
              name="notes"
              control={control}
            />
          </div>
        </Card>
        <div className="m-auto mt-4 w-full md:w-3/6">
          <Button title="Continue" disabled={!isValid} />
        </div>
      </form>
    </Container>
  );
});
