import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { CountryPhoneCode, Kitchen } from '@calo/types';
import downArrow from '../../assets/icons/arrow-down.svg';
import { TextInput } from '../TextInput';
import { useKitchenStore } from '../../store';
import { assets } from '../../assets';
import { uniqBy } from 'lodash-es';
import { shallow } from 'zustand/shallow';
import { AVAILABLE_KITCHENS_DATA } from '../../store/kitchenStore';
import { usePhoneNumberParser } from '../../hooks';

type CountryPhoneInputProps = {
  onChangeText: (text: string) => void;
};

export const CountryPhoneInput = forwardRef(({ onChangeText }: CountryPhoneInputProps, ref) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const { getPhoneNumber } = usePhoneNumberParser();
  const { selectedKitchenData, setSelectedKitchen } = useKitchenStore(
    (state) => ({
      selectedKitchenData: state.selectedKitchenData!,
      setSelectedKitchen: state.setSelectedKitchen,
    }),
    shallow,
  );
  const onChangeCountry = useCallback(
    (kitchen: Kitchen) => {
      setPhoneNumber('');
      setSelectedKitchen(kitchen);
    },
    [setSelectedKitchen],
  );
  const countries = useMemo(() => uniqBy(Object.values(AVAILABLE_KITCHENS_DATA), 'country'), []);
  return (
    <div className="flex items-center">
      <div className="dropdown mt-4 w-2/6">
        <span className="flex border border-grey-1 h-11 rounded-l-md border-r-0 px-2" tabIndex={0}>
          <span className="px-5 py-2 flex justify-center gap-2">
            <img className="py-1" alt="flag" src={assets.flags[selectedKitchenData.country]} />
            <label className="mt-1">{CountryPhoneCode[selectedKitchenData.country]}</label>
            <img alt="arrow-down" width={12} height={12} src={downArrow} />
          </span>
        </span>
        <ul tabIndex={0} className="w-full dropdown-content p-2 shadow-md bg-base-100 rounded-md md:h-28 overflow-y-scroll">
          {countries.map(({ country, kitchen }) => (
            <li
              key={kitchen}
              onClick={() => {
                if (selectedKitchenData.country !== country) onChangeCountry(kitchen);
                const elem = document.activeElement as HTMLElement;
                elem?.blur?.();
              }}>
              <span className="px-2 py-3 flex">
                <img alt="flag" src={assets.flags[country]} />
                <label>{CountryPhoneCode[country]}</label>
              </span>
            </li>
          ))}
        </ul>
      </div>
      <TextInput
        placeholder="Mobile Number"
        containerClass="w-full"
        textInputClass="rounded-r-md rounded-none"
        value={phoneNumber}
        onChangeText={(text) => {
          const newPhoneNumber = getPhoneNumber(text);
          setPhoneNumber(newPhoneNumber);
          onChangeText(CountryPhoneCode[selectedKitchenData.country] + newPhoneNumber);
        }}
      />
    </div>
  );
});
