import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
  Addons,
  Checkout,
  ContactDetails,
  Deliveries,
  DeliveryAddress,
  DeliveryDetails,
  Donation,
  NotFound,
  PaymentLoader,
} from '../pages';
import { ENV } from '../env';

const router = createBrowserRouter([
  {
    path: '/',
    element: ENV.DONATION ? <Donation /> : <Addons />,
  },
  {
    path: '/addons',
    element: <Addons />,
  },
  {
    path: '/donation',
    element: <Donation />,
  },
  {
    path: '/checkout',
    element: <Checkout />,
  },
  {
    path: '/delivery-address',
    element: <DeliveryAddress />,
  },
  {
    path: '/contact-details',
    element: <ContactDetails />,
  },
  {
    path: '/deliveries/:id',
    element: <DeliveryDetails />,
  },
  {
    path: '/deliveries',
    element: <Deliveries />,
  },
  {
    path: '/payment',
    element: <PaymentLoader />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
