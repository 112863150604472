import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type GeneralStore = {
  isAuth: boolean;
  setAuth: (isAuth: boolean) => void;
  paymentSessionId: string | null;
  setPaymentSessionId: (paymentSessionId: string) => void;
  resetPaymentSessionId: () => void;
};
export const useGeneralStore = create<GeneralStore>()(
  persist(
    (set, get) => ({
      paymentSessionId: null,
      setPaymentSessionId: (paymentSessionId) => set({ paymentSessionId }),
      resetPaymentSessionId: () => set({ paymentSessionId: null }),
      isAuth: false,
      setAuth: (isAuth) => set({ isAuth }),
    }),
    {
      name: 'cart-store',
    },
  ),
);
