import { useQuery } from '@tanstack/react-query';
import { getServiceStatus } from '../services/ServiceStatusService';

export const useServiceStatus = () => {
  const { data: serviceStatusData } = useQuery(['/service-status'], () => getServiceStatus());

  return {
    serviceStatusData,
  };
};
