import React, { useCallback, useLayoutEffect } from 'react';
import { useDonationSettings } from '../../hooks';
import { useLocation } from 'react-router-dom';

const modalId = 'donation-success-modal';

export const DonationSuccess = () => {
  const handleModalState = useCallback((checked: boolean) => {
    const modal = document.getElementById(modalId) as HTMLInputElement;
    if (modal) {
      modal.checked = checked;
    }
  }, []);

  const onPressClose = useCallback(() => {
    handleModalState(false);
  }, [handleModalState]);

  const { donationSettings } = useDonationSettings();

  const location = useLocation();
  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get('success') === 'true') {
      handleModalState(true);
    }
  }, [location.search, handleModalState]);

  return donationSettings ? (
    <>
      <input type="checkbox" id={modalId} className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-full md:modal-box md:w-full md:h-fit fixed bottom-0 rounded-b-none md:top-0 md:m-auto">
          <p className="text-base" onClick={onPressClose}>
            ✕
          </p>
          <div className="flex flex-col items-center">
            <img alt="charity-logo" width={200} src={donationSettings.charityInfo.logo} />
            <p className="font-bold text-2xl">Thank you for your donation!</p>
            <p className="my-8 text-sm text-center">
              The world is a better place with people like you 💚
            </p>
          </div>
        </div>
      </div>
    </>
  ) : null;
};
