import client from './api/client';
import { AddressType, Country, NewDeliveryAddress } from '@calo/types';
import { AddressService } from '@calo/services';

interface ValidateLocationReq {
  country: Country;
  lat: number;
  lng: number;
}

export const countryLatLng: Partial<Record<Country, { lat: number; lng: number }>> = {
  [Country.BH]: {
    lat: 26.214730891806386,
    lng: 50.58297045528889,
  },
  [Country.SA]: {
    lat: 24.709081,
    lng: 46.7247173,
  },
  [Country.AE]: {
    lat: 25.2048,
    lng: 55.2708,
  },
  [Country.KW]: {
    lat: 29.291065271775114,
    lng: 47.91488192975521,
  },
};

export const defaultLocation: Partial<Record<Country, NewDeliveryAddress>> = {
  [Country.BH]: {
    city: 'Adliya',
    country: Country.BH,
    district: 'Block 327',
    ...countryLatLng[Country.BH]!,
    region: 'Capital Governorate',
    street: 'Rd No 2766',
    building: '',
    type: AddressType.home,
  },
  [Country.SA]: {
    city: 'King Abdul Aziz',
    country: Country.SA,
    ...countryLatLng[Country.SA]!,
    region: 'Riyadh Province',
    street: 'Abi Bakr As Siddiq Rd',
    building: '',
    type: AddressType.home,
  },
  [Country.AE]: {
    city: 'Al Wasl',
    country: Country.AE,
    ...countryLatLng[Country.AE]!,
    region: 'Dubai',
    street: 'D71',
    building: '96',
    type: AddressType.home,
  },
  [Country.KW]: {
    city: 'Herafi Ardiya',
    country: Country.KW,
    ...countryLatLng[Country.KW]!,
    region: 'Al Farwaniyah Governorate',
    street: '',
    building: '',
    type: AddressType.home,
  },
};

export const checkLocationDelivery = async (value: ValidateLocationReq) => {
  const { data } = await client().get('v2/verification/location', {
    params: value,
  });
  return data as { withinDeliveryArea: boolean };
};

export const getAddress = (
  geocoder: any,
  lat: number,
  lng: number,
): Promise<NewDeliveryAddress> => {
  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        location: {
          lat,
          lng,
        },
      },
      (result: any, status: any) => {
        if (status !== 'OK') {
          reject(status);
        }
        resolve(AddressService.parseAddress(result) as NewDeliveryAddress);
      },
    );
  });
};
