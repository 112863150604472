import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { merge } from 'lodash-es';
import { QueryClient } from '@tanstack/react-query';
import { Auth } from '@aws-amplify/auth';
import { ENV } from '../../env';

const tokenExtractor = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

let client: AxiosInstance;
let authClient: AxiosInstance;

export const initApi = (config: AxiosRequestConfig) => {
  if (!client) {
    client = axios.create(
      merge(
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
        config,
      ),
    );
  }

  if (!authClient) {
    authClient = axios.create(
      merge(
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
        config,
      ),
    );

    authClient.interceptors.request.use(
      async (options) => {
        try {
          const token = await tokenExtractor();
          options.headers['Authorization'] = `Bearer ${token}`;
          return options;
        } catch (error) {
          return {
            ...options,
            cancelToken: new axios.CancelToken((cancel) => cancel()),
          };
        }
      },
      (error) => Promise.reject(error),
    );
  }
};

const clinet = (type: 'auth' | 'default' = 'default') => {
  if (!authClient || !client) {
    initApi({
      baseURL: `${ENV.API_URL}/v1`,
    });
  }

  switch (type) {
    case 'auth':
      return authClient;
    default:
      return client;
  }
};

export default clinet;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});
