import { useCallback } from 'react';
import { PaymentMethod } from '@calo/types';
import { paymentPolling } from '../services/PaymentService';
import { useGeneralStore } from '../store';
import { shallow } from 'zustand/shallow';

type PaymentProps<T> = Omit<T, 'cardId' | 'redirectUrl'> & {
  paymentMethod: PaymentMethod | string;
};

type HandlePaymentProps<T> = PaymentProps<T> & {
  paymentMethod: PaymentMethod | string;
  cardId?: string;
  redirectUrl?: string;
};

type NewPaymentResponse = {
  link: string;
  sessionId: string;
};

type SavedPaymentResponse = {
  sessionId: string;
};

type PaymentFinishedResponse = {
  isSuccess: boolean;
  error?: { title: string; message: string };
};
type UseVerifyPaymentProps = {
  onSuccessfulPayment: () => void;
  onFailedPayment?: (error?: string, title?: string) => void;

  setLoading?: (loading: boolean) => void;
};

type UsePaymentProps<T> = UseVerifyPaymentProps & {
  onPay: (props: PaymentProps<T>) => Promise<NewPaymentResponse | SavedPaymentResponse>;

  paymentName: string;
};

export const useVerifyPayment = ({
  onSuccessfulPayment,
  onFailedPayment,
  setLoading,
}: UseVerifyPaymentProps) => {
  const { paymentSessionId, resetPaymentSessionId } = useGeneralStore(
    (state) => ({
      paymentSessionId: state.paymentSessionId,
      resetPaymentSessionId: state.resetPaymentSessionId,
    }),
    shallow,
  );
  const onFinish = useCallback(
    (props: PaymentFinishedResponse) => {
      const { isSuccess, error } = props;
      if (isSuccess) {
        onSuccessfulPayment();
      } else {
        onFailedPayment?.(error?.message, error?.title);
      }
      setLoading?.(false);
      resetPaymentSessionId();
    },
    [resetPaymentSessionId, onFailedPayment, onSuccessfulPayment, setLoading],
  );

  const verifyPayment = useCallback(
    async (recursiveCount = 2) => {
      try {
        if (!paymentSessionId) throw new Error('Payment session id is not defined');
        await paymentPolling(paymentSessionId);
        onFinish({ isSuccess: true });
      } catch (error: any) {
        if (error?.response?.status === 304 && recursiveCount > 0) {
          verifyPayment(recursiveCount - 1);
        } else {
          onFinish({
            isSuccess: false,
            error: error?.response?.data?.error,
          });
        }
      }
    },
    [paymentSessionId, onFinish],
  );

  return { verifyPayment, onFinish };
};

export const usePayment = <T>({
  onPay,
  onSuccessfulPayment,
  onFailedPayment,
  setLoading,
  paymentName,
}: UsePaymentProps<T>) => {
  const { verifyPayment, onFinish } = useVerifyPayment({
    onSuccessfulPayment,
    onFailedPayment,
    setLoading,
  });

  const { setPaymentSessionId } = useGeneralStore(
    (state) => ({
      setPaymentSessionId: state.setPaymentSessionId,
    }),
    shallow,
  );

  const handlePayment = useCallback(
    async (paymentProps: HandlePaymentProps<T>) => {
      setLoading?.(true);
      try {
        if (!(paymentProps.paymentMethod in PaymentMethod)) {
          paymentProps.cardId = paymentProps.paymentMethod;
          paymentProps.paymentMethod = PaymentMethod.cc;
        }
        const { sessionId, link } = (await onPay({
          ...paymentProps,
          redirectUrl: `${window.location.origin}/payment?action=${paymentName}`,
        })) as NewPaymentResponse;
        setPaymentSessionId(sessionId);
        if (link) {
          window.open(link, '_self');
        } else {
          verifyPayment();
        }
      } catch (error: any) {
        onFinish({
          isSuccess: false,
          error: error?.response?.data?.error,
        });
      }
    },
    [onFinish, onPay, paymentName, setLoading, setPaymentSessionId, verifyPayment],
  );

  const pay = useCallback(
    async (paymentProps: PaymentProps<T>) => {
      return handlePayment(paymentProps);
    },
    [handlePayment],
  );

  return { pay };
};
