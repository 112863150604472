import React, { useMemo } from 'react';
import basketIcon from '../../assets/icons/bag.svg';
import { Button } from '../Button';
import { useNavigate } from 'react-router-dom';
import { useCartStore } from '../../store';
import { shallow } from 'zustand/shallow';
import { CartType } from '../../store/cartStore';

export const EmptyCart = () => {
  const navigate = useNavigate();
  const { cartType } = useCartStore(
    (state) => ({
      cartType: state.cartType,
    }),
    shallow,
  );

  const props = useMemo(() => {
    if (cartType === CartType.DONATION) {
      return {
        title: 'No donations yet?',
        description: 'Start spreading the spirit of giving this holy month.',
        button: 'Donate',
      };
    }
    return {
      title: 'No items added yet',
      description: 'Start adding and satisfy your cravings',
      button: 'Add item',
    };
  }, [cartType]);

  return (
    <div className="flex flex-col items-center justify-center">
      <img width={60} height={60} src={basketIcon} alt="basket" />
      <p className="font-bold text-base p-3">{props.title}</p>
      <p className="text-md text-primary">{props.description}</p>
      <Button
        onClick={() => navigate('/')}
        title={props.button}
        className="border-primary mt-6 w-3/6"
      />
    </div>
  );
};
