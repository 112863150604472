import { useKitchenStore } from '../store';
import { useQuery } from '@tanstack/react-query';
import { shallow } from 'zustand/shallow';
import { Food, Localized } from '@calo/types';
import { getDonationSettings } from '../services/DonateService';

export type DonationSettingsRes = {
  numberOfMealsRange: {
    max: number;
    default: number;
    min: number;
  };
  price: number;
  donationMenu: Partial<Food> & { id: string };
  charityInfo: {
    name: Localized;
    logo: string;
  };
};

type UseDonationSettingsProps = {
  enabled?: boolean;
};

export const useDonationSettings = ({ enabled }: UseDonationSettingsProps = { enabled: false }) => {
  const { selectedKitchenData } = useKitchenStore(
    (state) => ({
      selectedKitchenData: state.selectedKitchenData,
    }),
    shallow,
  );

  const { data: donationSettings } = useQuery<DonationSettingsRes>(
    ['/donation/settings', selectedKitchenData?.kitchen ?? ''],
    () => getDonationSettings(selectedKitchenData!.kitchen),
    {
      enabled: enabled && !!selectedKitchenData,
    },
  );

  return {
    donationSettings,
  };
};
