import { Country, CountryPhoneCode } from '@calo/types';
import { AsYouType } from 'libphonenumber-js';
import { useKitchenStore } from '../store';
import { useCallback } from 'react';

const getAyt = (phoneNumber: string, country: Country) => {
  const ayt = new AsYouType({
    defaultCountry: country,
    defaultCallingCode: CountryPhoneCode[country],
  });

  ayt.input(phoneNumber);

  return ayt;
};

export const usePhoneNumberParser = () => {
  const {
    selectedKitchenData: { country },
  } = useKitchenStore((state) => ({
    selectedKitchenData: state.selectedKitchenData!,
  }));

  const getPhoneNumber = useCallback(
    (phoneNumber: string) => getAyt(phoneNumber, country).getNumber()?.nationalNumber || '',
    [country],
  );

  return {
    getPhoneNumber,
  };
};
