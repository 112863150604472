import React, { PropsWithChildren } from 'react';
import { ScrollRestoration } from 'react-router-dom';

export const Container = ({ children }: PropsWithChildren) => {
  return (
    <div className="container px-4 py-4">
      <ScrollRestoration />
      {children}
    </div>
  );
};
