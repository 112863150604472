import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../Button';
import { CountryPhoneInput } from '../CountryPhoneInput';
import { TextInput } from '../TextInput';
import { useSignIn } from '../../hooks';
import { useCartStore } from '../../store';
import caloLogo from '../../assets/icons/calo.svg';
import helpLogo from '../../assets/icons/help.svg';
import greenArrowBack from '../../assets/icons/green-arrow-back.svg';
import { shallow } from 'zustand/shallow';
import { isValidPhoneNumber } from 'libphonenumber-js';

type SigninProps = {
  modalId: string;
};
export const Signin = ({ modalId }: SigninProps) => {
  const [shouldReRender, setShouldReRender] = useState(false);
  const [showError, setShowError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [codeDisabled, setCodeDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setDeliveryAddress } = useCartStore(
    (state) => ({
      setDeliveryAddress: state.setDeliveryAddress,
    }),
    shallow,
  );

  const successCallback = useCallback(
    (data: any) => {
      setDeliveryAddress(data.deliveryAddress);
    },
    [setDeliveryAddress],
  );

  const failureCallback = useCallback(() => {
    setShowError(true);
  }, []);

  const { onSubmit, showCode, phoneNumber, code, resendIn, onResendCode, setShowCode } = useSignIn({
    setLoading,
    successCallback,
    failureCallback,
  });
  const onClose = () => {
    phoneNumber.current = '';
    code.current = '';
    setShowCode(false);
    setCodeDisabled(false);
    setDisabled(true);
    setShowError(false);
    setLoading(false);
    setShouldReRender(!shouldReRender);
    const myModal = document?.getElementById(modalId) as HTMLInputElement;
    if (myModal) myModal.checked = false;
  };

  useEffect(() => {
    setCodeDisabled(showCode);
  }, [showCode]);

  return (
    <>
      <input type="checkbox" id={modalId} className="modal-toggle" />
      <div className="modal" key={shouldReRender + ''}>
        <div className="modal-box w-screen md:rounded-md md:h-fit md:w-full md:justify-center">
          <div className="flex items-center justify-between py-3 w-full">
            <div className="flex-1 h-4">
              <img alt="back" onClick={onClose} className="cursor-pointer " src={greenArrowBack} />
            </div>
            <img alt="calo" src={caloLogo} className="flex-1 h-6" />
            <span id="intercomMessenger" className="flex-1 justify-end flex gap-1 cursor-pointer">
              <img alt="help" src={helpLogo} />
              <label className="text-primary text-base">Help</label>
            </span>
          </div>
          <p className="text-base font-bold -mb-2">Phone Number</p>
          <CountryPhoneInput
            onChangeText={(text) => {
              setDisabled(!isValidPhoneNumber(text));
              phoneNumber.current = text;
            }}
          />
          <p className="text-t-secondary mt-1">
            You’ll receive a 6 digit code to verify your number via SMS
          </p>

          {showCode && (
            <TextInput
              placeholder="Enter the OTP"
              onChangeText={(text) => {
                setCodeDisabled(text.length < 6);
                code.current = text;
              }}
            />
          )}

          {showError && (
            <div className="text-red-500 mt-1">
              Looks like you already have an account, log in on the app to continue please
            </div>
          )}
          <div className="modal-action">
            <Button
              disabled={disabled || codeDisabled || showError}
              title="Sign in"
              className="border-white"
              onClick={onSubmit}
              isLoading={loading}
            />
          </div>
          {showCode && (
            <Button
              isLoading={loading}
              className="mt-2"
              title={`Resend code${resendIn ? ` in ${resendIn}s` : ''}`}
              disabled={!!resendIn || showError}
              onClick={onResendCode}
            />
          )}
        </div>

        <div
          className={`${
            showError ? '' : 'hidden'
          } w-screen px-4 py-2 absolute bottom-0 bg-primary`}>
          <div className="text-white text-xl font-bold">Seems like you are a Calo user</div>
          <div className="text-white font-bold mt-1">
            Use your calo account on the app and start purchasing your favourite Add-ons.
          </div>
          <div className="w-full md:w-64">
            <Button
              title="Go to App"
              onClick={() => {
                window.open('https://link.calo.app/micro');
              }}
              className="text-primary bg-white mt-5 hover:text-primary hover:bg-white"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
