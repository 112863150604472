import client from './api/client';
import { ActionSource, Kitchen } from '@calo/types';

export const buyAddonsPaused = async (values: any) => {
  try {
    const { data } = await client('auth').post('addons/purchase/', {
      ...values,
      source: ActionSource.WEB,
    });
    return data;
  } catch (error) {
    return null;
  }
};
export const getDeliveries = async () => {
  try {
    const { data } = await client('auth').get('addon/deliveries');
    return data?.data;
  } catch (error) {
    return null;
  }
};

export const getAddonsMenu = async (kitchen: Kitchen) => {
  try {
    const { data } = await client().get(`addons/${kitchen}`);
    return data?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
