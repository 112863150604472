import React from 'react';
import { useNavigate } from 'react-router-dom';
import loader from '../assets/images/loader.gif';
import { Button } from '../components';

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center h-screen text-center">
      <img alt="search" className="h-1/3 aspect-square object-contain" src={loader} />
      <span className="text-3xl font-bold mt-8">Oops seems the page can’t be found</span>
      <span className="text-lg font-bold mt-2">Lets go back to the Home Page</span>
      <Button
        title="Back to Home"
        onClick={() => navigate('/', { replace: true })}
        className="mt-6 md:w-1/6 w-5/6"
      />
    </div>
  );
};
