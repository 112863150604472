import React, { useState } from 'react';
import tickIcon from '../../assets/icons/tick.svg';
import { AddressType } from '@calo/types';

type AddressTypeProps = {
  defaultAddress: AddressType;
  onChange: (value: AddressType) => void;
};
export const AddressTypeInput = ({ defaultAddress, onChange }: AddressTypeProps) => {
  const [selectedAddress, setSelectedAddress] = useState(defaultAddress);
  const selectedClass = `border-primary text-primary`;
  const unSelectedClass = `border-grey-1 text-t-secondary`;

  const handleAddressChange = (value: AddressType) => {
    setSelectedAddress(value);
    onChange(value);
  };

  return (
    <div className="flex mt-3">
      <div
        onClick={() => handleAddressChange(AddressType.home)}
        className={`flex items-center border rounded-md px-2 py-1 font-medium cursor-pointer ${
          selectedAddress === AddressType.home ? selectedClass : unSelectedClass
        }`}>
        🏠 Home
        {selectedAddress === AddressType.home && <img alt="tick" className="ml-2" src={tickIcon} />}
      </div>
      <div
        onClick={() => handleAddressChange(AddressType.office)}
        className={`flex text-sm ml-3 items-center border rounded-md px-2 py-1 font-medium cursor-pointer ${
          selectedAddress === AddressType.office ? selectedClass : unSelectedClass
        }`}>
        💼 Office
        {selectedAddress === AddressType.office && (
          <img className="ml-2" src={tickIcon} alt="tick" />
        )}
      </div>
    </div>
  );
};
