import React, { PropsWithChildren } from 'react';

type CardProps = {
  className?: string;
  width?: string;
};
export const Card = ({
  children,
  className = '',
  width = 'md:w-3/6',
}: PropsWithChildren<CardProps>) => {
  return <div className={`shadow-md rounded-md p-4 w-full ${width} ${className}`}>{children}</div>;
};
