import React from 'react';
import { images } from '../../assets';
import { useCartStore, useKitchenStore } from '../../store';
import { shallow } from 'zustand/shallow';
import { useDonationSettings } from '../../hooks';
import { DonationType } from '../../store/cartStore';

export const DonationSummaryCard = () => {
  const { totalDonation, donationType } = useCartStore(
    (state) => ({
      totalDonation: state.totalDonation,
      donationType: state.donationType,
    }),
    shallow,
  );
  const { selectedKitchenData } = useKitchenStore(
    (state) => ({
      selectedKitchenData: state.selectedKitchenData!,
    }),
    shallow,
  );
  const { donationSettings } = useDonationSettings();
  return (
    <div className="flex">
      <img alt="donation" width={70} src={images.donationBox} />
      <div className="ml-3 w-full">
        <div className="flex justify-between">
          <p className="font-bold text-sm">{`Iftar Meal x${totalDonation}`}</p>
          <label className="font-bold text-sm">
            {(Number(donationSettings!.price) * Number(totalDonation)).toFixed(selectedKitchenData.digits)} {selectedKitchenData.currency}
          </label>
        </div>
        {donationType === DonationType.CHARITY ? (
          <div className="flex items-center mt-2">
            <img alt="donation" width={30} src={donationSettings!.charityInfo.logo} />
            <label className="ml-2">Meals sent to {donationSettings!.charityInfo.name.en}</label>
          </div>
        ) : null}
      </div>
    </div>
  );
};
