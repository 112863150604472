import { Country, DDeliveryStatus, DeliveryTime } from '@calo/types';

export enum GifNames {
  customFood = 'customFood',
  ResumedDelivery = 'ResumedDelivery',
  fullCapacityOnboarding = 'fullCapacityOnboarding',
  softLaunchWaitingList = 'softLaunchWaitingList',
  onboardingName = 'onboardingName',
  preLaunchWaitingList = 'preLaunchWaitingList',
  cashBackSuccess = 'cashBackSuccess',
  preLunchCoveredArea = 'preLunchCoveredArea',
}

export const times: Record<DeliveryTime, string> = {
  [DeliveryTime.morning]: '12 PM to 4 PM.',
  [DeliveryTime.evening]: '7 PM to 11 PM.',
};

export const deliveryStatuses: Record<DDeliveryStatus, string> = {
  [DDeliveryStatus.delivering]: 'Your menu has been confirmed and is being prepared by our chefs',
  [DDeliveryStatus.delivered]: 'Enjoy your meals!',
};
