import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import { useVerifyPayment } from '../hooks/usePayment';
import { CartType } from '../store/cartStore';

export const PaymentLoader = () => {
  const navigate = useNavigate();

  const paymentAction = useRef('');

  const onSuccessfulPayment = useCallback(() => {
    if (paymentAction.current === CartType.DONATION) {
      navigate('/?success=true', { replace: true });
    } else if (paymentAction.current === CartType.ADDONS) {
      navigate('/deliveries', { replace: true });
    }
  }, [navigate]);

  const { verifyPayment } = useVerifyPayment({
    onSuccessfulPayment,
  });

  const isVerifying = useRef(false);

  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paymentActionParam = searchParams.get('action');
    if (paymentActionParam) {
      if (!isVerifying.current) {
        isVerifying.current = true;
        paymentAction.current = paymentActionParam;
        verifyPayment();
      }
    } else {
      navigate('/', { replace: true });
    }
  }, [navigate, location.search, verifyPayment]);

  return (
    <div className="flex flex-col items-center justify-center h-full">Loading for payment ....</div>
  );
};
