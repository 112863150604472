import { useGeneralStore } from '../store';
import { shallow } from 'zustand/shallow';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const withAuth = (WrappedComponent: any, shouldBeAuth = true, to = '/') => {
  return (props: any) => {
    const { isAuth } = useGeneralStore(
      (state) => ({
        isAuth: state.isAuth,
      }),
      shallow,
    );

    const navigate = useNavigate();

    useEffect(() => {
      if (isAuth !== shouldBeAuth) navigate(to, { replace: true });
    }, [isAuth, navigate]);

    if (isAuth !== shouldBeAuth) return null;

    return <WrappedComponent {...props} />;
  };
};
