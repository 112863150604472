export const ENV = {
  API_URL: process.env.REACT_APP_API_URL || 'https://napi.dev.calo.app',
  CDN_URL: process.env.REACT_APP_CDN_URL || 'https://cdn.dev.calo.app',
  DOMAIN: process.env.REACT_APP_DOMAIN || 'localhost',
  GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_API || '',
  DONATION: Number(process.env.REACT_APP_DONATION || 0),
  AWS_USER_POOL: process.env.REACT_APP_AWS_USER_POOL || '',
  AWS_USER_POOL_CLIENT: process.env.REACT_APP_AWS_USER_POOL_CLIENT || '',
  AUTH_URL: process.env.REACT_APP_AUTH_URL || 'https://auth.dev.calo.app',
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
};
