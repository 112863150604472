import React from 'react';

type ButtonProps = {
  title: string;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const Button = ({
  title,
  className,
  isLoading = false,
  disabled = false,
  onClick = () => ({}),
  ...rest
}: ButtonProps) => (
  <input
    type="submit"
    value={title}
    className={`btn bg-primary rounded-md w-full disabled:text-neutral-50 hover:bg-primary hover:border-primary border-1 border-primary ${
      isLoading && 'loading'
    }  ${className}`}
    onClick={onClick}
    disabled={disabled || isLoading}
    {...rest}
  />
);
