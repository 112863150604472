import { useKitchenStore } from '../store';
import { useQuery } from '@tanstack/react-query';
import { Food } from '@calo/types';
import { getAddonsMenu } from '../services/AddonsService';
import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

type AddonsMenuRes = Food[];

type UseAddonsMenuProps = {
  enabled?: boolean;
};

export const useAddonsMenu = ({ enabled }: UseAddonsMenuProps = { enabled: false }) => {
  const { selectedKitchenData } = useKitchenStore(
    (state) => ({
      selectedKitchenData: state.selectedKitchenData,
    }),
    shallow,
  );

  const { data: addonsMenu } = useQuery<AddonsMenuRes>(
    ['/addons/menu', selectedKitchenData?.kitchen ?? ''],
    () => getAddonsMenu(selectedKitchenData!.kitchen),
    {
      enabled: enabled && !!selectedKitchenData,
    },
  );

  const getAddonById = useCallback(
    (id: string) => {
      return addonsMenu?.find((addon) => addon.id === id);
    },
    [addonsMenu],
  );

  return {
    addonsMenu,
    getAddonById,
  };
};
