import { create } from 'zustand';
import { Food } from '@calo/types';
import { ENV } from '../env';
import { NewDeliveryAddress } from '@calo/types/src/interfaces';
import { useKitchenStore } from './kitchenStore';

export enum AddonsAction {
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT',
}

export enum DonationType {
  CHARITY = 'CHARITY',
  CUSTOMER = 'CUSTOMER',
}

export enum CartType {
  ADDONS = 'ADDONS',
  DONATION = 'DONATION',
}

type DefaultCartStore = {
  resetCart: () => void;
  totalPrice: number;
  cartType: CartType;
  setCartType: (cartType: CartType) => void;
  deliveryAddress: NewDeliveryAddress | null;
  setDeliveryAddress: (deliveryAddress: NewDeliveryAddress) => void;
};

type AddonsStore = {
  cartAddons: Record<string, number>;
  updateAddon: (addon: Food, action: AddonsAction) => void;
};

type DonationStore = {
  totalDonation: number;
  donationType: DonationType;
  setDonations: (totalDonation: number, donationType: DonationType, donationPrice: number) => void;
};

export type CartStore = DefaultCartStore & AddonsStore & DonationStore;
export const useCartStore = create<CartStore>((set, get) => ({
  resetCart: () => {
    set({ cartAddons: {}, totalPrice: 0, totalDonation: 0, deliveryAddress: null });
  },
  totalPrice: 0,
  cartType: ENV.DONATION ? CartType.DONATION : CartType.ADDONS,
  setCartType: (cartType) => {
    const currentCartType = get().cartType;
    if (currentCartType === cartType) return;
    set({ cartType, cartAddons: {}, totalPrice: 0, totalDonation: 0 });
  },
  deliveryAddress: null,
  setDeliveryAddress: (deliveryAddress) => set({ deliveryAddress }),
  // addons
  cartAddons: {},
  updateAddon: (addon, action) => {
    const { cartAddons, totalPrice } = get();
    const addonQuantity = cartAddons[addon.id] || 0;
    if (action === AddonsAction.DECREMENT && addonQuantity === 0) return;
    const totalQuantity = addonQuantity + (action === AddonsAction.INCREMENT ? 1 : -1);
    const newTotalPrice = totalPrice + (action === AddonsAction.INCREMENT ? 1 : -1) * addon.price!;
    const { [addon.id]: currentAddonQuantity, ...rest } = cartAddons;
    const newCartAddons =
      currentAddonQuantity === 1 && action === AddonsAction.DECREMENT
        ? {}
        : {
            [addon.id]: totalQuantity,
          };
    set({
      cartAddons: {
        ...rest,
        ...newCartAddons,
      },
      totalPrice: Number(
        newTotalPrice.toFixed(useKitchenStore.getState().selectedKitchenData!.digits),
      ),
    });
  },
  // Donation
  totalDonation: 0,
  donationType: DonationType.CHARITY,
  setDonations: (totalDonation, donationType, donationPrice) => {
    get().setCartType(CartType.DONATION);
    set({ totalDonation, donationType, totalPrice: totalDonation * donationPrice });
  },
}));
