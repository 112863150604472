import React, { useEffect, useMemo } from 'react';
import { Container, Header, MessageBox, OrderItem, ScreenInfoHeader } from '../components';
import clockIcon from '../assets/icons/clock.svg';
import bikeIcon from '../assets/icons/bike.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { ENV } from '../env';
import { DDeliveryStatus } from '@calo/types';
import { useDeliveries } from '../hooks';
import { withAuth } from '../HOC';
import { deliveryStatuses, times } from '../constants';
import { uniqBy } from 'lodash-es';

export const DeliveryDetails = withAuth(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getDeliveryById } = useDeliveries();
  const delivery = getDeliveryById(id || '');
  const { addons, day, time, deliveryStatus } = delivery || {};
  const incorrectParams = !addons || !day || !time;
  useEffect(() => {
    if (incorrectParams) {
      navigate('/deliveries', { replace: true });
    }
  }, [incorrectParams, navigate]);

  if (incorrectParams) {
    return <></>;
  }

  const quantity = useMemo(
    () =>
      addons?.reduce((acc, addon) => {
        acc[addon.id] = (acc[addon.id] || 0) + 1;
        return acc;
      }, {} as Record<string, number>),
    [addons],
  );

  const uniqueAddons = useMemo(() => uniqBy(addons, 'id'), [addons]);

  return (
    <div>
      <Header />
      <div className="pt-20 md:pt-28">
        <Container>
          <ScreenInfoHeader title={day} />
          <div className="md:w-3/6 md:m-auto">
            <div className="flex gap-2 justify-center w-full mt-4">
              <MessageBox>
                <div className="w-full h-full p-3 flex flex-col justify-between">
                  <img width={24} alt="clock" className="mb-4" src={clockIcon} />
                  <p className="text-md">
                    {deliveryStatuses[deliveryStatus ?? DDeliveryStatus.delivering]}
                  </p>
                </div>
              </MessageBox>
              <MessageBox type="warning">
                <div className="w-full h-full p-3 flex flex-col justify-between">
                  <div className="flex">
                    <img width={24} alt="bike" className="mb-4" src={bikeIcon} />
                    <div className="ml-2">
                      <p className="font-bold text-xs">DELIVERING TO</p>
                      <label className="text-xs">OFFICE</label>
                    </div>
                  </div>
                  <p className="text-md">
                    Delivery is scheduled for <label className="font-bold">{times[time]}</label>
                  </p>
                </div>
              </MessageBox>
            </div>
            <div className="flex justify-center mt-6">
              <div className="p-3 w-full shadow-md rounded-md">
                {uniqueAddons.map((addon, index) => (
                  <OrderItem
                    key={addon.id}
                    name={addon.name.en}
                    description={addon.description.en}
                    calories={`${addon.macros.cal} kcal`}
                    image={`${ENV.CDN_URL}/food/${addon.id}/square@3x.jpg`}
                    isLast={index === uniqueAddons.length - 1}
                    price={addon.price!}
                    currency={addon.currency!}
                    quantity={quantity[addon.id]}
                  />
                ))}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
});
