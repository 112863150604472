import { useCallback, useEffect, useRef, useState } from 'react';
import { useGeneralStore, useKitchenStore } from '../store';
import { loginApi, resendCode, signIn, signUp, verifyCode } from '../services/AuthService';
import { CognitoUser } from '@aws-amplify/auth';
import { shallow } from 'zustand/shallow';

type UseSignInProps = {
  createAccount?: boolean;
  successCallback?: (data: any) => void;
  failureCallback?: () => void;
  setLoading: (loading: boolean) => void;
};
export const useSignIn = ({
  createAccount = false,
  successCallback,
  failureCallback,
  setLoading,
}: UseSignInProps) => {
  const [showCode, setShowCode] = useState(false);
  const [resendIn, setResendIn] = useState(0);
  const userRef = useRef<CognitoUser | null>(null);
  const phoneNumber = useRef('');
  const code = useRef('');
  const name = useRef('');

  const { setAuth } = useGeneralStore(
    (state) => ({
      setAuth: state.setAuth,
    }),
    shallow,
  );
  const { selectedKitchenData } = useKitchenStore(
    (state) => ({
      selectedKitchenData: state.selectedKitchenData!,
    }),
    shallow,
  );

  const login = useCallback(() => {
    setLoading(true);
    const handler = ({ cognitoUser, resendIn }: { cognitoUser: CognitoUser; resendIn: number }) => {
      userRef.current = cognitoUser;
      setShowCode(true);
      setResendIn(resendIn);
      setLoading(false);
    };
    if (createAccount) {
      signUp(phoneNumber.current, name.current)
        .then(handler)
        .catch(() => setLoading(false));
    } else {
      signIn(phoneNumber.current)
        .then(handler)
        .catch(() => setLoading(false));
    }
  }, [createAccount, setLoading]);

  useEffect(() => {
    let timeoutRef: NodeJS.Timeout;
    if (resendIn) {
      timeoutRef = setTimeout(() => {
        setResendIn(resendIn - 1);
      }, 1000);
    }
    return () => {
      if (timeoutRef) clearTimeout(timeoutRef);
    };
  }, [resendIn]);

  const verify = useCallback(() => {
    setLoading(true);
    if (userRef.current) {
      verifyCode(code.current, userRef.current)
        .then(() => {
          loginApi({
            kitchen: selectedKitchenData.kitchen,
            country: selectedKitchenData.country,
          })
            .then((data) => {
              successCallback?.(data);
              setLoading(false);
              setAuth(true);
            })
            .catch(() => {
              failureCallback?.();
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [failureCallback, selectedKitchenData, setAuth, setLoading, successCallback]);

  const onResendCode = useCallback(() => {
    if (userRef.current) {
      resendCode(userRef.current).then(({ resendIn }) => {
        setResendIn(resendIn);
      });
    }
  }, []);

  const onSubmit = useCallback(() => {
    if (showCode) verify();
    else login();
  }, [login, showCode, verify]);

  return { onSubmit, showCode, phoneNumber, code, resendIn, name, onResendCode, setShowCode };
};
