import client from './api/client';
import { DeliveryTime, Kitchen, NewDeliveryAddress } from '@calo/types';

export type DonationRequest = {
  mealsCount: number;
  date: string;
  deliverToCustomer: boolean;
  foodId: string;
  deliveryAddress?: NewDeliveryAddress;
  time?: DeliveryTime;
};

export const getDonationSettings = async (kitchen: Kitchen) => {
  try {
    const { data } = await client().get(`donation/${kitchen}/settings`);
    return data?.data;
  } catch (error) {
    return null;
  }
};

export const getDonationList = async () => {
  try {
    const { data } = await client('auth').get('donation/all');
    return data;
  } catch (error) {
    return null;
  }
};

export const donateMeals = async (
  values: DonationRequest & {
    paymentMethod: string;
    redirectUrl?: string;
    cardId?: string;
  },
) => {
  const { data } = await client('auth').post('donation/user', values);
  return data;
};
