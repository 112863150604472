import { Auth, CognitoUser } from '@aws-amplify/auth';
import client from './api/client';
import { Country, Kitchen } from '@calo/types';
import { ENV } from '../env';

interface AuthError {
  code: string;
}

const getCaptchaToken = async (action = 'login') => {
  try {
    const captchaToken = await new Promise<string>((resolve) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      grecaptcha.enterprise.ready(async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const token = await grecaptcha.enterprise.execute(ENV.RECAPTCHA_SITE_KEY, {
          action
        });
        resolve(token);
      });
    });

    return captchaToken;
  } catch {
    return 'ERROR';
  }
};

const isAuthError = (error: any): error is AuthError => {
  return error && error.code;
};

const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

const convertArabicDigitsToEnglish = (str: string) => {
  for (let i = 0; i < 10; i++) {
    str = str.replace(arabicNumbers[i], `${i}`);
  }
  return str;
};

export const signIn = async (phoneNumber: string) => {
  const captcha = await getCaptchaToken('login');
  const signInCognitoUser = await Auth.signIn(convertArabicDigitsToEnglish(phoneNumber.trim()), undefined, {
    captcha
  });

  const cognitoUser = await Auth.sendCustomChallengeAnswer(signInCognitoUser, 'PROVIDE_CHALLENGE_TYPE', {
    challengeType: 'OTP'
  });

  return {
    cognitoUser,
    challenge: cognitoUser.challengeParam.challenge,
    resendIn: parseInt(cognitoUser.challengeParam.resendIn),
  };
};

export const verifyCode = async (code: string, cognitoUser: CognitoUser) => {
  try {
    await Auth.sendCustomChallengeAnswer(
      cognitoUser,
      code.trim(),
      { challengeType: 'OTP' },
    );
  } catch (error) {
    // no-op
  }
  try {
    await Auth.currentAuthenticatedUser();
  } catch (error) {
    throw new Error('INVALID_CODE');
  }
};

export const resendCode = async (cognitoUser: CognitoUser, isCall?: boolean) => {
  const response = await Auth.sendCustomChallengeAnswer(
    cognitoUser,
    'RESEND_OTP',
    { challengeType: 'RESEND_OTP' }
  );
  return {
    resendIn: parseInt(response.challengeParam.resendIn),
  };
};

export const signUp = async (phoneNumber: string, name: string) => {
  phoneNumber = convertArabicDigitsToEnglish(phoneNumber.trim());
  try {
    const captcha = await getCaptchaToken('signup');

    await Auth.signUp({
      username: phoneNumber,
      password: Math.random().toString(36).slice(-8),
      attributes: {
        name: name.trim(),
        email: 'addons@calo.app'
      },
      clientMetadata: {
        captcha
      }
    });
  } catch (error) {
    if (isAuthError(error) && error.code === 'UsernameExistsException') {
      return await signIn(phoneNumber);
    } else {
      throw error;
    }
  }
  return signIn(phoneNumber);
};

export const loginApi = async (values: { kitchen: Kitchen; country: Country }) => {
  const { data } = await client('auth').post('login', values);
  return data;
};
