import React, { useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { ENV } from '../../env';
import LocationPin from '../../assets/icons/location-pin.svg';
import LocationPinGreyed from '../../assets/icons/location-pin-grayed.svg';
import ArrowBackWhite from '../../assets/icons/arrow-back.svg';
import Aim from '../../assets/icons/aim.svg';
import { Button } from '../Button';
import { checkLocationDelivery, defaultLocation, getAddress } from '../../services/LocationService';
import { useKitchenStore } from '../../store';
import { NewDeliveryAddress } from '@calo/types';
import Autocomplete from 'react-google-autocomplete';
import { AddressService } from '@calo/services';

let Geocoder: any;
const initGeocoder = ({ maps }: any) => {
  Geocoder = new maps.Geocoder();
};

const LocationPinComponent = ({ lat, lng }: { lat: number; lng: number }) => (
  <div className="-ml-5 -mt-10">
    <img alt="location" src={LocationPin} />
  </div>
);

type LocationPickerProps = {
  location: { lat: number; lng: number };
  deliveryAddress?: NewDeliveryAddress;
  onChangeLocation: (deliverAddress: NewDeliveryAddress) => void;
};
export const LocationPicker = ({
  location,
  deliveryAddress,
  onChangeLocation,
}: LocationPickerProps) => {
  const { selectedKitchenData } = useKitchenStore((state) => ({
    selectedKitchenData: state.selectedKitchenData!,
  }));
  const [mLocation, setLocation] = useState(location);
  const [address, setAddress] = useState(
    deliveryAddress || defaultLocation[selectedKitchenData.country]!,
  );
  const [outOfArea, setOutOfArea] = useState(false);

  const onSelect = async (location: { lat: number; lng: number }, shouldGeoCode = true) => {
    setLocation(location);
    if (shouldGeoCode) {
      getAddress(Geocoder, location.lat, location.lng).then((newAddress) => {
        setAddress(newAddress);
      });
    }

    checkLocationDelivery({
      lat: location.lat,
      lng: location.lng,
      country: selectedKitchenData.country,
    })
      .then((newLocation) => {
        setOutOfArea(!newLocation.withinDeliveryArea);
      })
      .catch(() => {
        setOutOfArea(true);
      });
  };

  const onClose = async (updateLocation = false) => {
    const myModal = document?.getElementById('location-picker-mobile') as HTMLInputElement;
    if (myModal) {
      myModal.checked = false;
      if (updateLocation) {
        onChangeLocation({ ...address, ...mLocation });
      } else {
        setLocation(location);
      }
    }
  };

  const panRef = useRef<any>(null);

  return (
    <>
      <input type="checkbox" id="location-picker-mobile" className="modal-toggle" />
      <label htmlFor="location-picker-mobile" className="modal cursor-pointer">
        <label
          htmlFor=""
          className="h-full w-full md:h-3/6 md:top-28 md:absolute md:modal-box md:w-11/12 md:max-w-5xl md:rounded-md">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: ENV.GOOGLE_MAP_KEY,
              libraries: ['places'],
            }}
            onGoogleApiLoaded={({ map, maps }) => {
              panRef.current = (lat: number, lng: number) => {
                const latLng = new maps.LatLng(lat, lng); // Makes a latlng
                map.panTo(latLng);
              };
              initGeocoder({ maps });
            }}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={mLocation}
            onClick={({ lat, lng }) => {
              onSelect({ lat, lng });
            }}
            defaultZoom={14}
            options={{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              clickableIcons: false,
            }}>
            <LocationPinComponent lat={mLocation.lat} lng={mLocation.lng} />
          </GoogleMapReact>
          <div className="h-fit absolute top-0 bottom-0 left-0 right-0">
            <div className="flex justify-center items-center">
              <img
                className="mr-3 mt-2 cursor-pointer shadow-md"
                src={ArrowBackWhite}
                alt="back"
                onClick={() => {
                  onClose(false);
                }}
              />
              <div className="shadow-md mt-0 w-[300px] ">
                <Autocomplete
                  placeholder="Enter location"
                  options={{
                    types: [],
                    componentRestrictions: {
                      country: selectedKitchenData.country,
                    },
                  }}
                  className="text-sm focus:outline-none placeholder-grey-2 input input-bordered w-full h-11 rounded-md border-grey-1 mt-2"
                  apiKey={ENV.GOOGLE_MAP_KEY}
                  onPlaceSelected={(place) => {
                    const location = place.geometry.location;
                    panRef.current?.(location.lat(), location.lng());
                    onSelect({ lat: location.lat(), lng: location.lng() }, false);
                    const newDeliveryAddress = AddressService.parseAddress([
                      place,
                    ]) as NewDeliveryAddress;
                    setAddress(newDeliveryAddress);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="h-fit absolute bottom-20  right-5">
            <img alt="aim" className="bg-white p-1 rounded-full cursor-pointer" src={Aim} />
          </div>
          <div className="h-fit absolute left-0 bottom-0 bg-white p-4 w-full">
            <div className="flex gap-2 pb-3">
              <img alt="location" src={LocationPinGreyed} />
              <label className="text-lg">
                {outOfArea ? 'Out of Delivery Area' : `${address.city}, ${address.region}`}
              </label>
            </div>
            <Button
              disabled={outOfArea}
              title="Confirm pin location"
              onClick={() => {
                onClose(true);
              }}
            />
          </div>
        </label>
      </label>
    </>
  );
};
