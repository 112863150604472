import { useCallback, useEffect, useState } from 'react';
import { Button } from '../Button';
import { useDonationSettings } from '../../hooks';
import { useCartStore, useKitchenStore } from '../../store';
import { shallow } from 'zustand/shallow';
import { DonationType } from '../../store/cartStore';
import { useNavigate } from 'react-router-dom';

const modalId = 'donation-option-modal';

type DonationOptionProps = {
  numberOfMeals: number;
};
export const DonationOptions = ({ numberOfMeals }: DonationOptionProps) => {
  const navigate = useNavigate();
  const { selectedKitchenData } = useKitchenStore(
    (state) => ({
      selectedKitchenData: state.selectedKitchenData!,
    }),
    shallow,
  );

  const { setDonations, deliveryAddress } = useCartStore(
    (state) => ({
      setDonations: state.setDonations,
      deliveryAddress: state.deliveryAddress,
    }),
    shallow,
  );

  const handleModalState = useCallback((checked: boolean) => {
    const modal = document.getElementById(modalId) as HTMLInputElement;
    if (modal) {
      modal.checked = checked;
    }
  }, []);

  const onPressClose = useCallback(() => {
    handleModalState(false);
  }, [handleModalState]);

  const openModal = useCallback(() => {
    handleModalState(true);
  }, [handleModalState]);

  const { donationSettings } = useDonationSettings();

  const [donationType, setDonationType] = useState(DonationType.CHARITY);

  useEffect(() => {
    if (donationSettings) {
      setDonationType(DonationType.CHARITY);
    }
  }, [donationSettings]);

  return (
    <>
      <Button
        className="border-primary mt-2"
        title={`Donate ${numberOfMeals} for ${numberOfMeals * donationSettings!.price} ${
          selectedKitchenData.currency
        }`}
        onClick={openModal}
      />
      <input type="checkbox" id={modalId} className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-full md:modal-box md:w-full md:h-fit fixed bottom-0 rounded-b-none md:top-0 md:m-auto">
          <p className="text-base" onClick={onPressClose}>
            ✕
          </p>
          <p className="mt-5 font-bold text-2xl">The donated meals will be sent to:</p>
          <div>
            {/* <div className="h-[1px] bg-grey-5 mt-5"></div> */}
            <div
              className="flex items-center justify-between mt-4"
              onClick={() => setDonationType(DonationType.CHARITY)}>
              <div className="flex items-center">
                <img alt="donation" width={30} src={donationSettings?.charityInfo.logo} />
                <label className="ml-2 font-normal text-sm">
                  {donationSettings?.charityInfo.name.en}
                </label>
              </div>
            </div>
            {/* <div className="h-[1px] bg-grey-5 mt-5"></div> */}
          </div>
          <Button
            className="mt-6 border-primary"
            title="Donate"
            onClick={() => {
              setDonations(numberOfMeals, donationType, donationSettings!.price);
              onPressClose();
              if (donationType === DonationType.CHARITY || deliveryAddress) {
                navigate('/contact-details');
              } else {
                navigate('/delivery-address');
              }
            }}
          />
          <Button
            className="text-primary bg-white mt-5 hover:text-white"
            title="Maybe later"
            onClick={onPressClose}
          />
        </div>
      </div>
    </>
  );
};
