import React, { useEffect, useLayoutEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import { Container, DonationOptions, DonationSuccess, Header } from '../components';
import { useCartStore, useKitchenStore } from '../store';
import { shallow } from 'zustand/shallow';
import { useDocumentMedia, useDonationSettings } from '../hooks';
import { CartType } from '../store/cartStore';
import { images } from "../assets";

export const Donation = () => {
  const { isMobile } = useDocumentMedia();
  const [donationMeals, setDonationMeals] = useState(0);
  const { selectedKitchenData } = useKitchenStore(
    (state) => ({
      selectedKitchenData: state.selectedKitchenData!,
    }),
    shallow,
  );
  const { setCartType } = useCartStore(
    (state) => ({
      setCartType: state.setCartType,
    }),
    shallow,
  );

  useEffect(() => {
    setCartType(CartType.DONATION);
  }, [setCartType]);

  const { donationSettings } = useDonationSettings({
    enabled: true,
  });

  useLayoutEffect(() => {
    if (donationSettings) {
      setDonationMeals(donationSettings.numberOfMealsRange.default);
    }
  }, [donationSettings]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (!donationSettings) {
  //       window.open('https://addons.calo.app/', '_self');
  //     }
  //   }, 5000);
  // }, []);

  return (
    <div>
      <Header />
      <div className="pt-20 md:pt-28">
        {donationSettings ? (
          <Container>
            <DonationSuccess />
            <div>
              <p className="text-2xl font-bold text-grey-4 md:text-3xl">
                Let’s spread the spirit of giving during the holy month 🌙
              </p>
              {isMobile && (
                <p className="text-base text-grey-4 py-2 md:text-lg">
                  Donate Iftar meals for those in need
                </p>
              )}
            </div>
            <div className="md:flex md:justify-around md:mt-8 md:gap-20">
              <img className="mt-2 w-full md:w-3/6 md:flex-1" src={images.ramadanDonation} />
              <div className="md:flex-1">
                {!isMobile && (
                  <p className="text-md text-grey-4 py-2 md:text-lg">
                    Donate Iftar meals for those in need
                  </p>
                )}
                <div className="bg-grey-5 my-3 rounded-md">
                  <p className="text-sm text-center text-grey-4 p-3">
                    1 meal = {donationSettings.price} {selectedKitchenData.currency}
                  </p>
                </div>
                <p className="font-bold text-sm">Number of meals:</p>
                <div className="flex items-center">
                  <label className="mr-2 text-sm text-grey-2">
                    {donationSettings.numberOfMealsRange.min}
                  </label>
                  <div className="w-full mt-12 mb-11">
                    <ReactSlider
                      className="flex items-center select-none outline-none"
                      thumbClassName="bg-primary border-primary h-5 w-5 rounded-full select-none outline-none cursor-pointer"
                      trackClassName="bg-grey-5 h-2 rounded-full absolute select-none outline-none"
                      markClassName="border-black select-none outline-none"
                      min={donationSettings.numberOfMealsRange.min}
                      max={donationSettings.numberOfMealsRange.max}
                      value={donationMeals}
                      onChange={(value) => setDonationMeals(value)}
                      renderThumb={(props, state) => (
                        <div {...props}>
                          <div
                            className={`text-lg absolute text-center bottom-6 font-bold text-primary select-none outline-none cursor-pointer ${
                              state.valueNow > 99 ? '-left-2' : state.valueNow > 9 ? '' : 'ml-1'
                            }`}>
                            {state.valueNow}
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <label className="ml-2 text-sm text-grey-2">
                    {donationSettings.numberOfMealsRange.max}
                  </label>
                </div>
                <DonationOptions numberOfMeals={donationMeals} />
              </div>
            </div>
          </Container>
        ) : (
          <div className="flex justify-center items-center">
            <p className="text-2xl font-bold md:text-3xl">
              Loading...
              {/* Thank you for contributing to feed people in ramadan! Hopefully we see you next year! */}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
