import React from 'react';
import GoogleMapReact from 'google-map-react';
import LocationPin from '../../assets/icons/location-pin.svg';
import { ENV } from '../../env';
import { Button } from '../Button';

type DeliveryLocationProps = {
  location: {
    lat: number;
    lng: number;
  };
  onClickEdit?: () => void;
};

const LocationPinComponent = ({ lat, lng }: { lat: number; lng: number }) => (
  <div className="-ml-5 -mt-10">
    <img alt="location" src={LocationPin} />
  </div>
);

export const DeliveryLocation = ({ location, onClickEdit }: DeliveryLocationProps) => {
  return (
    <div className="flex flex-col">
      <div className="h-32">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: ENV.GOOGLE_MAP_KEY,
            libraries: ['places'],
          }}
          center={location}
          defaultZoom={14}
          options={{
            draggable: false,
            panControl: false,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            clickableIcons: false,
          }}>
          <LocationPinComponent lat={location.lat} lng={location.lng} />
        </GoogleMapReact>
      </div>

      {onClickEdit && (
        <Button
          className="bg-white mt-3 text-primary hover:text-white"
          title="Edit Location Pin"
          onClick={onClickEdit}
        />
      )}
    </div>
  );
};
