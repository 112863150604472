import React from 'react';
import arrowBack from '../../assets/icons/arrow-back.svg';
import { useNavigate } from 'react-router-dom';

type ScreenInfoHeaderProps = {
  title: string;
  noBack?: boolean;
  navToHome?: boolean;
};
export const ScreenInfoHeader = ({ title, noBack, navToHome }: ScreenInfoHeaderProps) => {
  const navigate = useNavigate();
  return (
    <div className="flex mb-3">
      {!noBack && (
        <img
          alt="back"
          className="cursor-pointer"
          onClick={() => {
            if (navToHome) {
              navigate('/');
            } else {
              navigate(-1);
            }
          }}
          src={arrowBack}
        />
      )}
      <p className="text-2xl w-full text-center font-bold">{title}</p>
    </div>
  );
};
