import React, { useCallback, useMemo, useState } from 'react';
import {
  AddonSummaryCard,
  Button,
  Card,
  Container,
  DeliveryLocation,
  DonationSummaryCard,
  ScreenInfoHeader,
} from '../components';
import { useDonationSettings, usePayment } from '../hooks';
import { buyAddonsPaused } from '../services/AddonsService';
import { useNavigate } from 'react-router-dom';
import { Country, DeliveryTime, PaymentMethod } from '@calo/types';
import { useCartStore, useKitchenStore } from '../store';
import { shallow } from 'zustand/shallow';
import { donateMeals, DonationRequest } from '../services/DonateService';
import { withAuth, withCart } from '../HOC';
import { CartType, DonationType } from '../store/cartStore';
import cards from '../assets/icons/cards.svg';
import cash from '../assets/icons/cash.svg';
import { addDays, addWeeks, format, setDay } from 'date-fns/fp';

const DELIVERY_DAYS: Partial<Record<Country, number[]>> = {
  [Country.BH]: [0, 1, 2, 3, 4, 6],
  [Country.SA]: [0, 1, 2, 3, 4, 6],
  [Country.AE]: [0, 1, 2, 3, 4, 5],
  [Country.KW]: [0, 1, 2, 3, 4, 6],
};

const getMinActionDate = (country: Country, week = 0): Date => {
  const deliveryDays = DELIVERY_DAYS[country]!;
  const now = new Date();

  const lowerBorder = addDays(2)(now);

  if (deliveryDays.length === 0) {
    return lowerBorder;
  }
  const sorted = deliveryDays.sort();

  for (const day of sorted) {
    const startDay = addWeeks(week)(setDay(day)(now));
    if (startDay >= lowerBorder) {
      return startDay;
    }
  }
  return getMinActionDate(country, week + 1);
};

export const Checkout = withAuth(
  withCart(() => {
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.cc);
    const [deliveryTime, setDeliveryTime] = useState(DeliveryTime.morning);

    const navigate = useNavigate();
    const { donationSettings } = useDonationSettings();

    const { selectedKitchenData } = useKitchenStore(
      (state) => ({ selectedKitchenData: state.selectedKitchenData! }),
      shallow,
    );

    const { cartAddons, cartType, totalDonation, donationType, deliveryAddress, totalPrice } =
      useCartStore(
        (state) => ({
          cartAddons: state.cartAddons,
          cartType: state.cartType,
          totalDonation: state.totalDonation,
          donationType: state.donationType,
          deliveryAddress: state.deliveryAddress,
          totalPrice: state.totalPrice,
        }),
        shallow,
      );

    const onSuccessfulPayment = useCallback(() => {
      if (cartType === CartType.DONATION && donationType === DonationType.CHARITY) {
        navigate({
          pathname: '/',
          search: '?success=true',
        });
      } else {
        navigate('/deliveries');
      }
    }, [cartType, donationType, navigate]);

    const { pay: payAddons } = usePayment<any>({
      onPay: buyAddonsPaused,
      onSuccessfulPayment,
      setLoading,
      paymentName: CartType.ADDONS,
    });

    const { pay: payDonation } = usePayment<DonationRequest>({
      onPay: donateMeals,
      onSuccessfulPayment,
      setLoading,
      paymentName: donationType === DonationType.CHARITY ? CartType.DONATION : CartType.ADDONS,
    });

    const minActionDate = useMemo(
      () => getMinActionDate(selectedKitchenData.country),
      [selectedKitchenData],
    );

    return (
      <Container>
        <ScreenInfoHeader title="Summary" />

        <Card className="mb-3 m-auto">
          <div className="text-base font-bold mb-3">Summary</div>
          {cartType === CartType.DONATION ? <DonationSummaryCard /> : <AddonSummaryCard />}
        </Card>
        {cartType !== CartType.DONATION || donationType !== DonationType.CHARITY ? (
          <>
            <Card className="mb-3 m-auto">
              <div className="text-base font-bold mb-3">Delivery Address</div>
              <DeliveryLocation
                location={{
                  lat: deliveryAddress!.lat,
                  lng: deliveryAddress!.lng,
                }}
                onClickEdit={() => {
                  navigate('/delivery-address', { replace: true });
                }}
              />
            </Card>
            <Card className="mb-3 m-auto">
              <div className="text-base font-bold mb-3">Delivery Time</div>
              <label className="label">
                <span className="flex items-center gap-2">
                  <label className="text-sm">🗓</label>
                  <label className="text-sm">
                    {' '}
                    Delivery date:{' '}
                    <label className="font-bold">{format('EEEE, MMM d')(minActionDate)}</label>
                  </label>
                </span>
              </label>
              <label
                onClick={() => {
                  setDeliveryTime(DeliveryTime.morning);
                }}
                className="label cursor-pointer border-b pb-3 mb-1">
                <span className="flex items-center gap-2">
                  <label className="text-sm">🌤</label>
                  <label className="text-sm">12 PM - 4 PM (Morning)</label>
                </span>
                <input
                  readOnly
                  type="radio"
                  name="radio-1"
                  className="radio checked:bg-primary"
                  checked={deliveryTime === DeliveryTime.morning}
                />
              </label>
              <label
                onClick={() => {
                  setDeliveryTime(DeliveryTime.evening);
                }}
                className="label cursor-pointer">
                <span className="flex items-center gap-2">
                  <label className="text-sm">🌘</label>
                  <label className="text-sm">7 PM - 11 PM (the night before)</label>
                </span>
                <input
                  readOnly
                  type="radio"
                  name="radio-1"
                  className="radio checked:bg-primary"
                  checked={deliveryTime === DeliveryTime.evening}
                />
              </label>
            </Card>
          </>
        ) : null}
        <Card className="mb-3 m-auto">
          <div className="text-base font-bold mb-3">Payment</div>
          <label
            onClick={() => {
              setPaymentMethod(PaymentMethod.cc);
            }}
            className="label cursor-pointer">
            <span className="flex items-center gap-2">
              <img alt="cc" src={cards} />
              <label className="text-sm">Credit Card</label>
            </span>
            <input
              readOnly
              type="radio"
              name="radio-2"
              className="radio checked:bg-primary"
              checked={paymentMethod === PaymentMethod.cc}
            />
          </label>
          {selectedKitchenData.customPaymentMethod ? (
            <label
              onClick={() => {
                setPaymentMethod(selectedKitchenData.customPaymentMethod!);
              }}
              className="label cursor-pointer border-t mt-1 pt-3">
              <span className="flex items-center gap-2">
                <img alt="custom" src={cash} />
                <label className="text-sm capitalize">
                  {selectedKitchenData.customPaymentMethod}
                </label>
              </span>
              <input
                readOnly
                type="radio"
                name="radio-2"
                className="radio checked:bg-primary"
                checked={paymentMethod === selectedKitchenData.customPaymentMethod}
              />
            </label>
          ) : null}
        </Card>
        <Card className="mb-3 m-auto">
          <div className="text-base font-bold mb-3">Order Summary</div>
          <label className="label mt-1 pt-3 flex items-center gap-2">
            <label className="text-sm capitalize">Subtotal</label>
            <label className="text-sm font-bold">
              {totalPrice} {selectedKitchenData.currency}
            </label>
          </label>
          {cartType !== CartType.DONATION ? (
            <label className="label mt-1 pt-3 flex items-center gap-2">
              <label className="text-sm capitalize">
                VAT ({(selectedKitchenData.vat * 100).toFixed(0)}%)
              </label>
              <label className="text-sm font-bold">
                {Number((totalPrice * selectedKitchenData.vat).toFixed(selectedKitchenData.digits))}{' '}
                {selectedKitchenData.currency}
              </label>
            </label>
          ) : null}
          <label className="label border-t mt-1 pt-3 flex items-center gap-2">
            <label className="text-sm capitalize">Total</label>
            <label className="text-sm font-bold">
              {Number(
                (
                  totalPrice *
                  (1 + (cartType !== CartType.DONATION ? selectedKitchenData.vat : 0))
                ).toFixed(selectedKitchenData.digits),
              )}{' '}
              {selectedKitchenData.currency}
            </label>
          </label>
          <Button
            className="mt-4"
            isLoading={loading}
            title="Purchase"
            onClick={() => {
              if (cartType === CartType.DONATION) {
                payDonation({
                  paymentMethod,
                  ...(donationType === DonationType.CUSTOMER
                    ? { deliverToCustomer: true, deliveryAddress: deliveryAddress!, time: deliveryTime }
                    : { deliverToCustomer: false }),
                  mealsCount: totalDonation,
                  date: format('yyyy-MM-dd')(
                    donationType === DonationType.CHARITY ? new Date() : minActionDate,
                  ),
                  foodId: donationSettings!.donationMenu.id,
                });
              } else {
                payAddons({
                  paymentMethod,
                  addons: Object.keys(cartAddons).map((id) => ({
                    id,
                    quantity: cartAddons[id],
                  })),
                  date: format('yyyy-MM-dd')(minActionDate),
                  deliveryAddress,
                  time: deliveryTime,
                });
              }
            }}
          />
        </Card>
      </Container>
    );
  }),
);
