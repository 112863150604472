import React from 'react';
import {
  Card,
  Container,
  DeliveryItem,
  DonationItem,
  EmptyCart,
  Header,
  ScreenInfoHeader,
} from '../components';
import { useDeliveries } from '../hooks';
import { withAuth } from '../HOC';
import { useCartStore } from '../store';
import { shallow } from 'zustand/shallow';
import { CartType } from '../store/cartStore';

export const Deliveries = withAuth(() => {
  const { cartType } = useCartStore(
    (state) => ({
      cartType: state.cartType,
    }),
    shallow,
  );

  const { deliveries, isLoading } = useDeliveries({
    enabled: true,
  });

  return (
    <>
      <Header />
      <div className="pt-20 md:pt-28">
        <Container>
          <ScreenInfoHeader title="Your Orders" navToHome />
          <div className="flex justify-center">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <>
                {deliveries && deliveries.length ? (
                  <Card className="mt-4">
                    {deliveries.map((delivery: any, index) =>
                      cartType === CartType.DONATION ? (
                        <DonationItem
                          key={delivery.id}
                          deliveryDate={delivery.date}
                          numberOfItems={delivery.mealsCount}
                          addressType={delivery.deliveryAddress?.type}
                          deliveryTime={delivery.time}
                          isLast={index === deliveries.length - 1}
                        />
                      ) : (
                        <DeliveryItem
                          key={delivery.id}
                          id={delivery.id}
                          deliveryDate={delivery.day}
                          numberOfItems={delivery.addons?.length || 0}
                          addressType={delivery.deliveryAddress.type}
                          deliveryTime={delivery.time!}
                          isLast={index === deliveries.length - 1}
                        />
                      ),
                    )}
                  </Card>
                ) : (
                  <div className="flex flex-col justify-center py-28">
                    <EmptyCart />
                  </div>
                )}
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
});
