import React, { PropsWithChildren } from 'react';

type MessageBoxProps = {
  type?: 'primary' | 'warning' | 'none';
};

export const MessageBox = ({ children, type }: PropsWithChildren<MessageBoxProps>) => {
  return (
    <div
      className={`flex-1 shadow-md rounded-md ${
        type === 'warning' && 'border-l-4 border-warning'
      }`}>
      {children}
    </div>
  );
};
