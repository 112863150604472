import React from 'react';
import rightArrowIcon from '../../assets/icons/arrow-right.svg';
import { useNavigate } from 'react-router-dom';
import { AddressType, DeliveryTime } from '@calo/types';
import { times } from '../../constants';

type DeliveryItemProps = {
  id: string;
  deliveryDate: string;
  numberOfItems: number;
  addressType: AddressType;
  deliveryTime: DeliveryTime;
  isLast?: boolean;
};

export const DeliveryItem = ({
  id,
  deliveryDate,
  numberOfItems,
  addressType,
  deliveryTime,
  isLast,
}: DeliveryItemProps) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(`/deliveries/${id}`);
      }}>
      <div className="flex justify-between mb-4 p-2">
        <div className="flex gap-2">
          <div className="flex flex-col justify-between py-1">
            <div>
              <p className="font-bold text-base">Delivery Date: {deliveryDate}</p>
              <p className="italic text-xs">Number of items: {numberOfItems}</p>
            </div>
            <p className="text-xs font-bold mt-4">
              Delivering to: <label className="capitalize">{addressType}</label> -{' '}
              {times[deliveryTime]}
            </p>
          </div>
        </div>
        <img alt="arrow" className="cursor-pointer" src={rightArrowIcon} />
      </div>
      {!isLast && <div className="h-[2px] mb-4 bg-grey-5"></div>}
    </div>
  );
};
