import React, { useCallback, useEffect, useMemo } from 'react';
import { AddonsBox, AddonSummaryCard, Button, Card, Header } from '../components';
import { useCartStore, useKitchenStore } from '../store';
import { useNavigate } from 'react-router-dom';
import { useAddonsMenu } from '../hooks';
import { shallow } from 'zustand/shallow';
import { CartType } from '../store/cartStore';
import basketIcon from '../assets/icons/bag.svg';

export const Addons = () => {
  const { selectedKitchenData } = useKitchenStore((state) => ({
    selectedKitchenData: state.selectedKitchenData!,
  }));

  const { deliveryAddress } = useCartStore(
    (state) => ({
      deliveryAddress: state.deliveryAddress,
    }),
    shallow,
  );

  const { addonsMenu } = useAddonsMenu({
    enabled: true,
  });

  const { cartAddons, totalPrice, setCartType } = useCartStore(
    (state) => ({
      setCartType: state.setCartType,
      cartAddons: state.cartAddons,
      totalPrice: state.totalPrice,
    }),
    shallow,
  );

  useEffect(() => {
    setCartType(CartType.ADDONS);
  }, [setCartType]);

  const totalItems = useMemo(
    () => Object.values(cartAddons).reduce((acc: any, item) => acc + item, 0),
    [cartAddons],
  );

  const navigate = useNavigate();

  const showFooter = Object.keys(cartAddons).length > 0;

  const navToNext = useCallback(() => {
    if (deliveryAddress) {
      navigate('/contact-details');
    } else {
      navigate('/delivery-address');
    }
  }, [deliveryAddress, navigate]);

  return (
    <>
      <div className={`${showFooter ? 'pb-28' : 'pb-20'}`}>
        <Header />
        <div className="px-4 md:px-12 lg:px-28 xl:px-44 2xl:px-60">
          <div className="pt-20 pb-10 md:pt-28">
            <div className="font-bold text-sm pb-1">
              <span>Welcome 👋</span>
            </div>
            <span className="font-bold text-2xl">
              What are <span className="text-primary">you</span> craving?
            </span>
          </div>
          {addonsMenu ? (
            <div className="flex items-start w-full gap-6">
              <div className="grid grid-cols-2 gap-4 w-full lg:grid-cols-3 flex-initial md:w-7/12 lg:w-2/3">
                {addonsMenu.map((addon, index) => {
                  return <AddonsBox key={addon.id + index} addon={addon} />;
                })}
              </div>
              <Card className="hidden md:block flex-initial sticky top-28" width="md:w-5/12 lg:w-1/3">
                <div className="text-base font-bold mb-3">Items Added</div>
                {showFooter ? (
                  <>
                    <AddonSummaryCard />
                    <Button
                      className="mt-5"
                      onClick={navToNext}
                      title={`${totalItems} item added \t-\t ${totalPrice} ${selectedKitchenData.currency}`}
                    />
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center">
                    <img width={60} height={60} src={basketIcon} alt="basket" />
                    <p className="font-bold text-base p-3">There are no items added</p>
                  </div>
                )}
              </Card>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
      {showFooter && (
        <div className="fixed z-10 bottom-0 w-full p-4 pb-6 bg-white text-2xl text-black md:hidden">
          <Button
            onClick={navToNext}
            title={`${totalItems} item added \t-\t ${totalPrice} ${selectedKitchenData.currency}`}
          />
        </div>
      )}
    </>
  );
};
