import { Country } from '@calo/types';
import aeFlag from './icons/uae-flag.svg';
import saFlag from './icons/sa-flag.svg';
import bhFlag from './icons/bh-flag.svg';
import kwFlag from './icons/kw-flag.svg';
import donationBox from './images/donation-box.png';
import ramadanDonation from './images/ramadanDonation.png';

export const assets: {
  flags: Partial<Record<Country, any>>;
} = {
  flags: {
    [Country.AE]: aeFlag,
    [Country.SA]: saFlag,
    [Country.BH]: bhFlag,
    [Country.KW]: kwFlag,
  },
};

export const images = {
  donationBox,
  ramadanDonation
};
