import React from 'react';
import { useCartStore, useKitchenStore } from '../../store';
import { shallow } from 'zustand/shallow';
import { useAddonsMenu } from '../../hooks';
import { AddonsAction } from '../../store/cartStore';
import { ENV } from '../../env';

export const AddonSummaryCard = () => {
  const { getAddonById } = useAddonsMenu();
  const { cartAddons, updateAddon } = useCartStore(
    (state) => ({
      cartAddons: state.cartAddons,
      updateAddon: state.updateAddon,
    }),
    shallow,
  );
  const { selectedKitchenData } = useKitchenStore(
    (state) => ({
      selectedKitchenData: state.selectedKitchenData!,
    }),
    shallow,
  );
  return (
    <>
      {Object.keys(cartAddons)
        .sort()
        .map((key, index) => (
          <div
            key={key}
            className={`flex ${index === Object.keys(cartAddons).length - 1 ? '' : 'mb-6'}`}>
            <img
              alt="addon"
              className="w-14 h-14"
              src={`${ENV.CDN_URL}/food/${key}/square@3x.jpg`}
            />
            <div className="flex justify-between flex-col ml-3 w-full">
              <div className="flex justify-between">
                <p className="font-bold text-sm flex-1">{`${cartAddons[key]}x ${
                  getAddonById(key)!.name.en
                }`}</p>
                <label className="font-bold text-sm">
                  {Number(
                    (cartAddons[key] * getAddonById(key)!.price!).toFixed(
                      selectedKitchenData.digits,
                    ),
                  )}{' '}
                  {selectedKitchenData.currency}
                </label>
              </div>
              <div className="flex items-center mt-2">
                <div
                  onClick={() => {
                    updateAddon(getAddonById(key)!, AddonsAction.DECREMENT);
                  }}
                  className="text-base cursor-pointer rounded-md w-6 text-center bg-white text-primary border-2 border-primary rounded-r-none">
                  -
                </div>
                <div className="h-full items-center flex text-md px-2 text-center border-2 border-r-0 border-l-0">
                  {cartAddons[key]}
                </div>
                <div
                  onClick={() => {
                    updateAddon(getAddonById(key)!, AddonsAction.INCREMENT);
                  }}
                  className="text-base cursor-pointer rounded-md text-center bg-white text-primary border-2 border-primary rounded-l-none w-6">
                  +
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
