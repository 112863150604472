import React, { useMemo } from 'react';
import { ENV } from '../../env';
import { AddonsAction, useCartStore } from '../../store/cartStore';
import { shallow } from 'zustand/shallow';
import { Food } from '@calo/types';

type AddonsBoxProps = {
  addon: Food;
};
export const AddonsBox = ({ addon }: AddonsBoxProps) => {
  const {
    id,
    name,
    description,
    macros: { cal },
    price,
    currency,
  } = addon;
  const { updateAddon, cartAddons } = useCartStore(
    (state) => ({
      updateAddon: state.updateAddon,
      cartAddons: state.cartAddons,
    }),
    shallow,
  );
  const currentQuantity = useMemo(() => cartAddons[id] || 0, [cartAddons, id]);
  return (
    <div className="bg-white shadow-md rounded-md overflow-hidden flex flex-col">
      <img
        className="w-full aspect-[5/3] object-cover"
        src={`${ENV.CDN_URL}/food/${id}/square@3x.jpg`}
        alt="calo"
      />
      <div className="p-3 flex-1 flex flex-col justify-between">
        <div>
          <div>{name.en}</div>
          <div className="italic text-note">{description.en}</div>
        </div>
        <div className="pt-3 flex flex-row justify-between items-center">
          <div>
            <div className="text-t-secondary text-xs">
              <span className="font-bold">{cal}</span> kcal
            </div>
            <div className="font-bold">
              <span className="text-base">{price}</span> {currency}
            </div>
          </div>
          <div className="flex">
            {currentQuantity > 0 && (
              <>
                <div
                  onClick={() => {
                    updateAddon(addon, AddonsAction.DECREMENT);
                  }}
                  className="text-xl cursor-pointer rounded-md w-6 text-center bg-white text-primary border-2 border-primary rounded-r-none">
                  -
                </div>
                <div className="text-lg px-2 text-center border-2 border-r-0 border-l-0">
                  {currentQuantity}
                </div>
              </>
            )}
            <div
              onClick={() => {
                updateAddon(addon, AddonsAction.INCREMENT);
              }}
              className={`text-xl cursor-pointer rounded-md text-center ${
                currentQuantity > 0
                  ? 'bg-white text-primary border-2 border-primary rounded-l-none w-6'
                  : 'bg-primary text-white w-8'
              }`}>
              +
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
