import React, { InputHTMLAttributes } from 'react';

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  title?: string;
  type?: string;
  isError?: boolean;
  isOptional?: boolean;
  containerClass?: string;
  textInputClass?: string;
  onChangeText?: (value: string) => void;
  multiLine?: boolean;
};

export const TextInput = ({
  value,
  defaultValue,
  placeholder,
  title,
  containerClass,
  textInputClass,
  isOptional,
  isError,
  type = 'text',
  onChangeText,
  multiLine,
}: TextInputProps) => {
  return (
    <div className={`mt-2 ${containerClass}`}>
      {title ? (
        <>
          <label className="text-base font-bold">{title}</label>
          <span className="ml-1 text-base">
            {isOptional && <label className="text-grey-1 font-bold text-xs">(Optional)</label>}
          </span>
        </>
      ) : null}
      {multiLine ? (
        <textarea
          value={value}
          defaultValue={defaultValue}
          onChange={(text) => onChangeText?.(text.target.value)}
          placeholder={placeholder}
          className="focus:outline-none placeholder-grey-2 py-2 input input-bordered w-full rounded-md border-grey-1 mt-2 h-16"
        />
      ) : (
        <input
          value={value}
          onChange={(text) => onChangeText?.(text.target.value)}
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className={`focus:outline-none placeholder-grey-2 input input-bordered w-full h-11 rounded-md border-grey-1 mt-2 ${
            isError && 'border-red-600'
          } ${textInputClass}`}
        />
      )}
    </div>
  );
};
