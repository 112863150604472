import { useQuery } from '@tanstack/react-query';
import { Delivery } from '@calo/types';
import { getDeliveries } from '../services/AddonsService';
import { useCallback } from 'react';
import { useCartStore } from '../store';
import { shallow } from 'zustand/shallow';
import { getDonationList } from '../services/DonateService';
import { CartType } from '../store/cartStore';

type DeliveriesRes = Delivery[];

type UseDeliveriesProps = {
  enabled?: boolean;
};
export const useDeliveries = ({ enabled }: UseDeliveriesProps = { enabled: false }) => {
  const { cartType } = useCartStore(
    (state) => ({
      cartType: state.cartType,
    }),
    shallow,
  );

  const { data: addonsDeliveries, isLoading: isAddonsLoading } = useQuery<DeliveriesRes>(
    ['/deliveries/addons', cartType],
    () => getDeliveries(),
    {
      enabled: enabled && cartType === CartType.ADDONS,
    },
  );

  const { data: donationDeliveries, isLoading: isDonationLoading } = useQuery<DeliveriesRes>(
    ['/deliveries/donation', cartType],
    () => getDonationList(),
    {
      enabled: enabled && cartType === CartType.DONATION,
    },
  );

  const getDeliveryById = useCallback(
    (id: string) => {
      return ((cartType === CartType.ADDONS ? addonsDeliveries : donationDeliveries) ?? []).find(
        (delivery) => delivery.id === id,
      );
    },
    [addonsDeliveries, cartType, donationDeliveries],
  );

  return {
    deliveries: cartType === CartType.DONATION ? donationDeliveries : addonsDeliveries,
    isLoading: cartType === CartType.DONATION ? isDonationLoading : isAddonsLoading,
    getDeliveryById,
  };
};
