import React, { useMemo } from 'react';
import { AddressType, DeliveryTime } from '@calo/types';
import { images } from '../../assets';
import { times } from '../../constants';
import { format, subDays } from 'date-fns/fp';
import { useDonationSettings } from '../../hooks';

type DonationItemProps = {
  deliveryDate: string;
  numberOfItems: number;
  addressType?: AddressType;
  deliveryTime?: DeliveryTime;
  isLast?: boolean;
};

export const DonationItem = ({
  deliveryDate,
  numberOfItems,
  addressType,
  deliveryTime,
  isLast,
}: DonationItemProps) => {
  const { donationSettings } = useDonationSettings({
    enabled: true,
  });

  const date = useMemo(() => {
    if (addressType) {
      return new Date(deliveryDate);
    }
    return subDays(2)(new Date(deliveryDate));
  }, [addressType, deliveryDate]);

  return donationSettings ? (
    <>
      <div className="flex">
        <img alt="donation" className="rounded-md" width={100} src={images.donationBox} />
        <div className="ml-3 flex flex-col justify-between py-1 w-full">
          <p className="font-bold text-sm">{`Iftar Meal x${numberOfItems}`}</p>
          <div>
            <p className="font-bold text-xs">{`${
              addressType ? 'Delivery Date:' : 'Donated on:'
            } ${format('dd/MM/yyyy')(date)}`}</p>
            <p className="text-xs font-bold mt-1">
              Delivering to:{' '}
              <label className="capitalize">
                {addressType || donationSettings.charityInfo.name.en}
              </label>
              {deliveryTime ? `- ${times[deliveryTime]}` : ''}
            </p>
          </div>
        </div>
      </div>

      {!isLast && <div className="mb-4"></div>}
    </>
  ) : null;
};
