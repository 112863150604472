import React, { useCallback, useEffect, useState } from 'react';
import { Country } from '@calo/types';
import {
  Button,
  Card,
  Container,
  CountryPhoneInput,
  ScreenInfoHeader,
  TextInput,
} from '../components';
import { GifNames } from '../constants';
import { getGif } from '../utils';
import { useSignIn } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { withAuth, withCart } from '../HOC';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const ContactDetails = withAuth(
  withCart(() => {
    const navigate = useNavigate();
    const successCallback = useCallback(() => navigate('/checkout', { replace: true }), [navigate]);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [codeDisabled, setCodeDisabled] = useState(false);
    const failureCallback = useCallback(() => {
      setShowError(true);
    }, []);
    const { onSubmit, showCode, phoneNumber, code, resendIn, onResendCode, name } = useSignIn({
      createAccount: true,
      successCallback,
      setLoading,
      failureCallback,
    });


    useEffect(() => {
      setCodeDisabled(showCode);
    }, [showCode]);
    return (
      <>
        <Container>
          <ScreenInfoHeader title="Enter your details" />
          <Card className="m-auto">
            <p className="text-sm font-medium">Hey good lookin’ 😉</p>
            <img
              className="w-full mt-4"
              src={getGif({ country: Country.AE, name: GifNames.onboardingName })}
              alt="contact-gif"
            />
            <TextInput
              placeholder="What's your name?"
              textInputClass="h-11"
              onChangeText={(text) => {
                name.current = text;
              }}
            />
            <CountryPhoneInput
              onChangeText={(text) => {
                setDisabled(!isValidPhoneNumber(text));
                phoneNumber.current = text;
              }}
            />
            <p className="text-t-secondary text-xs mt-1">
              You’ll receive a 6 digit code to verify your number via SMS
            </p>
            {showCode && (
              <TextInput
                placeholder="Enter OTP?"
                textInputClass="h-11"
                onChangeText={(text) => {
                  setCodeDisabled(text.length < 6);
                  code.current = text;
                }}
              />
            )}
            {showError && (
              <div className="text-red-500 mt-1">
                Looks like you already have an account, log in on the app to continue please
              </div>
            )}

            <Button
              disabled={disabled || codeDisabled || showError}
              title="Continue"
              className="mt-5"
              onClick={onSubmit}
              isLoading={loading}
            />
            {showCode && (
              <Button
                isLoading={loading}
                title={`Resend code${resendIn ? ` in ${resendIn}s` : ''}`}
                className="mt-5"
                disabled={!!resendIn || showError}
                onClick={onResendCode}
              />
            )}
          </Card>
        </Container>
        <div
          className={`${
            showError ? '' : 'hidden'
          } w-screen px-4 py-2 absolute bottom-0 bg-primary`}>
          <div className="text-white text-xl font-bold">Seems like you are a Calo user</div>
          <div className="text-white font-bold mt-1">
            Use your calo account on the app and start purchasing your favourite Add-ons.
          </div>
          <div className="w-full md:w-64 lg:w-64 xl:w-64">
            <Button
              title="Go to App"
              onClick={() => {
                window.open('https://link.calo.app/micro');
              }}
              className="text-primary bg-white mt-5 hover:text-primary hover:bg-white"
            />
          </div>
        </div>
      </>
    );
  }),
  false,
  '/checkout',
);
