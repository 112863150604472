import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Country, Currency, Kitchen, PaymentMethod } from '@calo/types';
import { useCartStore } from './cartStore';

type KitchenData = {
  kitchen: Kitchen;
  country: Country;
  currency: Currency;
  customPaymentMethod?: PaymentMethod;
  vat: number;
  digits: number;
};

export const AVAILABLE_KITCHENS_DATA: Partial<Record<Kitchen, KitchenData>> = {
  [Kitchen.BH1]: {
    kitchen: Kitchen.BH1,
    country: Country.BH,
    currency: Currency.BHD,
    customPaymentMethod: PaymentMethod.benefit,
    vat: 0.1,
    digits: 3,
  },
  [Kitchen.SA1]: {
    kitchen: Kitchen.SA1,
    country: Country.SA,
    currency: Currency.SAR,
    customPaymentMethod: PaymentMethod.mada,
    vat: 0.1,
    digits: 2,
  },
  [Kitchen.SA2]: {
    kitchen: Kitchen.SA2,
    country: Country.SA,
    currency: Currency.SAR,
    customPaymentMethod: PaymentMethod.mada,
    vat: 0.1,
    digits: 2,
  },
  [Kitchen.KW1]: {
    kitchen: Kitchen.KW1,
    country: Country.KW,
    currency: Currency.KWD,
    customPaymentMethod: PaymentMethod.knet,
    vat: 0,
    digits: 3,
  },
  [Kitchen.AE1]: {
    kitchen: Kitchen.AE1,
    country: Country.AE,
    currency: Currency.AED,
    vat: 0.05,
    digits: 2,
  },
};

type KitchenStore = {
  selectedKitchenData: KitchenData | null;
  setSelectedKitchen: (kitchen: Kitchen) => void;
  hack: string | null;
  setHack: (hack: string | null) => void;
};
export const useKitchenStore = create<KitchenStore>()(
  persist(
    (set, get) => ({
      hack: null,
      setHack: (hack: string | null) => set(() => ({ hack })),
      selectedKitchenData: null,
      setSelectedKitchen: (kitchen: Kitchen) => {
        const kitchenData = AVAILABLE_KITCHENS_DATA[kitchen];
        if (kitchen !== get().selectedKitchenData?.kitchen) {
          useCartStore.getState().resetCart();
        }
        set(() => ({ selectedKitchenData: kitchenData }));
      },
    }),
    {
      name: 'kitchen-store',
    },
  ),
);
