import { useCartStore } from '../store';
import { shallow } from 'zustand/shallow';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const withCart = (WrappedComponent: any) => {
  return (props: any) => {
    const { totalPrice } = useCartStore(
      (state) => ({
        totalPrice: state.totalPrice,
        setDeliveryAddress: state.setDeliveryAddress,
      }),
      shallow,
    );

    const navigate = useNavigate();

    useEffect(() => {
      if (totalPrice === 0) navigate('/', { replace: true });
    }, [navigate, totalPrice]);

    if (totalPrice === 0) return null;

    return <WrappedComponent {...props} />;
  };
};
